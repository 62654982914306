<div class="container h-100">
    <h1 class="mt-4">Privacy/data protection information</h1>
    <p class="mt-3">
      <b>"Data protection declaration" and "Information on the processing of personal data pursuant to Art. 13, 14 DSGVO"</b>
    </p>
    
    <h2 class="mt-4">introduction</h2>
  
    <p>
      The following "Data Protection Declaration" and the "Information on the processing of personal data in accordance with Art. 13, 14 DSGVO" explain what data we collect about you, what we need this data for and to whom we pass this data on. In addition, they also include your rights in relation to your data and the contact persons you can contact for further information or inquiries.
    </p>
    
    <p>
      While the "Privacy Policy" is primarily intended to explain what data we process, store and protect about you when you use "our website" (as defined below), the "Information on the processing of personal data pursuant to Art. 13, 14 DSGVO" is primarily intended to explain the processing of your data within the scope of our services for you/our customers and within the scope of all other activities that are part of the performance of our business activities.
    </p>
      
    <p>Last revision: May 2019</p>
  
    <h2 class="mt-4">I. Privacy policy</h2>
  
    <h3>To whom does this privacy policy apply and what is covered by it?</h3>
  
    <p>
      This privacy policy applies exclusively to the specific website of <b>deloitte.ai</b>, for which Deloitte Consulting GmbH, based in ("Deloitte", "we", "us" or "our") is responsible.
    </p>
  
    <p>
      We are committed to protecting your privacy and processing your data in an open and transparent manner.
    </p>
  
    <p>
      This privacy policy explains how we process, store and protect information about you when you use our website. We also explain how we process your data when we provide services to you/our customers and in all other activities that are part of the performance of our business.
    </p>
  
    <p>
      When this policy refers to "our website" or "this website", it refers to the specific website at <b>deloitte.ai</b>.
    </p>
  
    <p>
      This Privacy Policy also contains information about when we share your personal information with other members of the Deloitte Network (nationally independent member firms or affiliates of Deloitte Touche Tohmatsu Limited ("DTTL") (together the "Deloitte Network") ("About Deloitte") and other third parties (for example our service providers).
    </p>
  
    <p>
      If you provide us with information via the Deloitte Website, you agree in accordance with Art. 6 paragraph 1 letter a, 7 of the DSGVO (German Data Protection Act) that we may process your personal data in accordance with the principles set out here. This also applies to any necessary transfer of personal data to foreign member companies of Deloitte Touche Tohmatsu Limited. Please note in this context that certain links (electronic references) on the German Deloitte website lead to the websites of third parties or other member companies of Deloitte Touche Tohmatsu Limited which, due to their national autonomy and independence as well as any differing laws there, are not subject to the content of this privacy statement. Deloitte Consulting GmbH assumes no liability for the content and/or the data protection treatment of data left on these other websites.
    </p>
      
    <h3>to other areas of deloitte.ai</h3>
  
    <p>
      Please note that in addition to the <b>deloitte.ai</b> website, other country-specific, regional and service-specific websites are offered on <b>deloitte.ai</b>. These are provided by other companies/entities within the Deloitte network, e.g. the deloitte.com site is provided by Deloitte GmbH Wirtschaftsprüfungsgesellschaft and not by us. The provisions of this Privacy Policy do not apply to these websites or any other websites to which this Website may be linked. We encourage visitors to our website to review the privacy statements of any of these other websites before disclosing personal information.
    </p>
  
    <h3>Which data will be collected?</h3>
    <p>
        Although the use of our Website is not conditional upon the transmission of your personal data and we do not store any personal data via the Deloitte Website without your consent, we may process personal data of you if you voluntarily provide us with such data for specific purposes in the designated areas of our Website.
      </p>
    
      <p>
        Within the scope of providing services for you/our customers and carrying out necessary preliminary checks in connection with our services (e.g. conflict or "know-your-client" checks required by regulations) or within the scope of discussing possible services to be provided by us, we process personal data about you to the extent necessary.
      </p>
    
      <p>
        We may collect or receive this information because you provide it to us (for example, on a form on our website), because we have received it from other people (for example, your employer or consultant or from third parties we have engaged to assist us in carrying out our business, to the extent permitted by law) or because it is publicly available.
      </p>
    
      <p>
        In order to improve the operation of our Websites for you and to ensure their effective functionality, we use cookies (small text files that are offered/stored in the user's browser) and web beacons (small image files that allow the Deloitte Website to determine the number of visitors for a specific area of the Deloitte Website and to request certain stored cookies). We use these instruments to collect user data such as IP address, domain, browser type, language, access times and the pages visited on our Website. This collected information is forwarded to our webmaster to ensure that our website remains a useful and effective source of information.
      </p>
    
      <p>
        Neither our cookies nor our web beacons collect personal information such as names or e-mail addresses. To prevent your Internet browser from receiving cookies or web beacons in the first place, you should set your Internet browser to either not receive cookies or web beacons at all or to prompt you for your consent to receive each cookie or web beacon. Please read our Cookie Notice for more information on how to disable these tools. However, the DeloitteDigital Website may require the receipt of a cookie and/or web beacon and may otherwise deny visitors access to certain areas.
      </p>
    
      <p>
        You can use opt-out to prevent your anonymous Internet activities on websites from being recorded by analysis cookies. We use the following service providers : Google Analytics & Adobe Analytics (as described below). For more information about their privacy policies, how you can use the opt-out and additional information about cookies, please click on the following links:
      </p>
      
      <p>Adobe: <a href="http://www.adobe.com/de/privacy/opt-out.html">http://www.adobe.com/de/privacy/opt-out.html</a></p>
    
      <h3>Deloitte Cookie Note</h3>
    
      <p>
        The personal data we collect or obtain about you may include the following: Name; age; date of birth; gender; e-mail address; country of birth; employment and education information (for example, which company you work for, your job title and information about your education); financial and tax information (for example, where you reside for income and tax purposes); your postings on blogs, forums, wikis and any other social media applications and services provided by us; your IP address; your browser type and language; your access times; complaint details; details of how you use our products and services; details of how you prefer to interact with us and other similar information.
      </p>
    
      <p>
        The personal data processed by us may also include so-called "sensitive" or "special categories" of personal data, provided that you provide us with such data voluntarily. The types of personal data and special categories of personal data processed by us may vary depending on the type of services we provide to you/our customers or the way you use our website.
      </p>

    <p>
        We underline the importance of the protection of the private and personal sphere of minors (explicitly children under the age of 13), especially with regard to the possible misuse of today's Internet communication. For this reason, we would like to emphasize that our website and services are not designed for children or intentionally targeted at minors. We do not knowingly collect or store information about minors./p>
      </p>
        
      <h3>How will your data be processed by us?</h3>
        
      <h4>Processing of personal data collected via our website</h4>
      
      <p>
        In addition to the above-mentioned purposes in connection with our business activities, we may also process your personal data collected via our website:
      </p>
    
      <ul>
        <li>
          to administer and improve our website,
        </li>
        <li>
          to customize the content of our website to provide you with a personalized user experience and to direct your attention to information about our products and services that may be of interest to you,
        </li>
        <li>
          to manage and respond to all requests you make to us through our website,
        </li>
        <li>
          for the purposes for which the visitor has voluntarily consented within the meaning of Art. 6 paragraph 1 letter a, 7 DPA, such as in the context of an application or registration to receive general or exclusive information, as well as for purposes that registered users could expect according to the circumstances of website use.
        </li>
      </ul>
    
      <p>
        If, as a registered visitor, you no longer wish to use the information offered or other areas, you can object to further processing of your personal data for the future at any time. To do so, please contact Deloitte Consulting GmbH as the person responsible within the meaning of the DSGVO, Schwannstraße 6, 40476 Düsseldorf or <a href="mailto:kontakt@deloitte.de">kontakt@deloitte.de</a>. The objection shall not give rise to any costs other than the transmission costs according to the basic rates.
      </p>
    
      <h4>Processing of personal data for the provision of services to our customers</h4>
    
      <p>
        We process your personal data for the purpose of implementing individual contractual relationships, for the provision of services for you/our customers. In this context, we may process your personal data in the course of a correspondence regarding the services. Such correspondence may take place with you, our client, other members of the Deloitte network, our service providers or competent authorities. We may also process your personal data in order to conduct necessary preliminary checks in connection with our services (e.g. conflict or "know-your-client" checks) or in the course of discussing possible services to be provided by us.
      </p>
    
      <p>
        Because we offer our customers a wide range of services, the way in which we process personal data in relation to our services also varies. For example, we may use personal data:
      </p>
    
      <ul><li>
        about the employees and contractors of a customer during the performance of a software implementation (or similar activity) for a customer
      </li></ul>
      
      <h4>processing of personal data for other activities that are part of our business activities
    
      <p>
        We may also process your personal data in connection with the following purpose related to the original purpose (depending on necessity and professional admissibility in individual cases):
      </p>
    
      <ul>
        <li>
          applicable legal or regulatory obligations,
        </li>
        <li>
          Inquiries and notifications from competent authorities within the framework of relevant professional secrecy obligations under professional law,
        </li>
        <li>
          purposes of accounting, invoicing and risk analysis,
        </li>
        <li>
          purposes of the customer relationship, among others: (i) sending you Thought Leadership or information about our products and services that we believe may be of interest to you; (ii) contacting you to obtain feedback on our services;
and (iii) contacting you for other market or research purposes.to the extent that the special legal requirements are met;
</li>
<li>
Services that we receive from our specialist consultants such as lawyers, auditors and consultants,
</li>
<li>
administrative purposes in connection with the specific business activity,
</li>
<li>
Protection of our rights and those of our customers.
</li>
</ul>

<h3>Legal basis for the processing of personal data</h3>

<p>
We process your personal data for the above-mentioned purposes on the basis of the following legal principles: (a) because of our legitimate interest in the effective provision of our services to you and our customers; (b) because of our legitimate interest in the effective and lawful exercise of our business activities, unless your interests outweigh this interest; (c) because of the legal obligations applicable to us;(d) because the data is necessary to provide our services to you/our customer.
</p>

<p>
To the extent that we process sensitive personal data relating to you for any of the above purposes, we do so either because: (i) you have given us your express consent to process such data; (ii) we are required by law to process such data to ensure that we comply with our "know your client" and "anti-money laundering" obligations (or other legal obligations applicable to us); (iii) the processing is necessary to comply with our obligations under employment, social security or social security law; (iv) the processing is necessary to establish, exercise or defend legal claims; or (v) you have made the data public.
</p>

<p>
Where we are required by law to obtain your express consent in order to offer you certain promotional materials, we will only offer you such materials if we have received such consent from you. If you no longer wish to receive further promotional materials from us, you can click on the unsubscribe function in the message or send an e-mail to <a href="mailto:kontakt@deloitte.de">kontakt@deloitte.de</a> and object to the processing of your personal data for these purposes at any time without stating reasons. The objection will not incur any costs other than the transmission costs according to the basic tariffs.
</p>

<h3>Who do we pass on your data to?</h3>

<p>
In relation to one or more of the subjects mentioned in the section "How we process your data?"we may disclose details about you to: other members of the Deloitte Network; third parties who provide services to us and/or the Deloitte Network; competent authorities (including courts and authorities supervising us or other members of the Deloitte Network); your employer and/or its advisors; your advisors; organisations that assist us in identifying fraud; and other third parties who legitimately request access to personal data relating to you for one or more of the purposes set out in the section "How we process your data". In any case, data will only be passed on if this is also permitted under consideration of relevant confidentiality obligations.
</p>

<p>
Our Site hosts various blogs, forums, wikis and other social media applications or services that allow you to share content with other users (collectively, "Social Media Applications"). However, when using these social media applications, please always be aware that the information disseminated via this information channel can (also) be read, collected, stored and/or used by other users of the application. We have little or no control over these other users and therefore cannot guarantee that all information you contribute to the social media applications will be handled in accordance with this privacy policy. We therefore accept no responsibility for these persons or for how these persons handle your (personal) data.
</p>

<p>
Please note that some of the above recipients of your personal information may be located in countries outside the European Union, whose data protection laws may be less comprehensive. In these cases we will ensure that appropriate security measures have been taken, to protect your personal data in accordance with our legal obligations. If the recipient is not a member of the Deloitte Network, an appropriate security measure may be a data transfer agreement with the recipient based on standard contractual clauses for the transfer of personal data to third countries recognised by the European Commission.
</p>

<p>
Further details of the above-mentioned transmissions and the appropriate security measures implemented by Deloitte in relation to these transmissions are also available from us. Please contact us at <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a>.
</p>

<p>
We may also need to disclose your personal information if we are required to do so by law, regulatory authority or legal process. 
</p>

<p>
We may share non-personal, anonymous, and aggregate information with third parties for a variety of purposes, including data analysis, research, proposal preparation, thought leadership, and promotional purposes.
</p>

<p>
Please note, if you transmit data to other member companies of Deloitte Touche Tohmatsu Limited, that the individual member companies are nationally autonomous and independent, are generally subject to a law that differs from the DSGVO and, where applicable, have made a declaration that differs from this with regard to the protection of privacy and personal data. We would therefore like to ask you to read the respective privacy statements before you request the transfer of your personal data to certain other member companies of Deloitte Touche Tohmatsu Limited. This also applies if you instruct us to pass on your personal data to them. Deloitte Consulting GmbH assumes no liability for the content and/or data protection treatment of data that you or we leave on other websites at your request.
</p>

<h3>Protection of your personal data</h3>

<p>
Deloitte Consulting GmbH uses technologically generally accepted security standards to protect visitor data entered on the German website from misuse, loss and falsification. In addition, only certain Deloitte employees are authorised to access visitor data that is personally identifiable. These employees ensure that the confidentiality of this sensitive data is maintained within the scope of the purpose for which the data is transferred. This policy also applies to the website of other participating companies (including certain member companies of Deloitte Touche Tohmatsu Limited) and their employees, agents and affiliates to whom visitor information is disclosed in accordance with the purpose for which the information is provided, in accordance with the confidentiality policies of those companies.
</p>

<p>
All visitors to our websites are also advised that links (electronic "references") on the German website lead to other websites and information provided by third parties. Unless expressly warranted above, Deloitte Consulting GmbH does not assume any responsibility for the content of third-party websites, not even with regard to compliance with certain security standards or compliance with the basic data protection regulation.
</p>

<p>
Notwithstanding the foregoing with regard to visitor data on the German website, we use various physical, electronic and operational measures to ensure that your personal data is generally secure, accurate and up to date. These measures include, but are not limited to 
</p>

<ul>
<li>
Education and training of the relevant employees to ensure that they are aware of data protection obligations when handling personal data,
</li>
<li>
administrative and technical controls to limit access to personal data to persons who need to know ("need to know" principle),
</li>
<li>
technological security measures, including firewalls, encryption and anti-virus software
</li>
<li>
physical security measures, such as security badges for employees to access our premises
</li>
</ul>

<p>
If the recipient is not a member of the Deloitte network, an appropriate security measure may be a data transfer agreement with the recipient based on standard contractual clauses for the transfer of personal data to third countries recognised by the European Commission.
</p>

<p>
Further details of the above-mentioned transmissions and the appropriate security measures implemented by Deloitte in relation to these transmissions are also available from us. Please contact us at <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a>.
</p>

<p>
We may also need to disclose your personal information if we are required to do so by law, regulatory authority or legal process. 
</p>

<p>
We may share non-personal, anonymous, and aggregate information with third parties for a variety of purposes, including data analysis, research, proposal preparation, thought leadership, and promotional purposes.
</p>

<p>
Please note, if you transmit data to other member companies of Deloitte Touche Tohmatsu Limited, that the individual member companies are nationally autonomous and independent, are generally subject to a law that differs from the DSGVO and, where applicable, have made a declaration that differs from this with regard to the protection of privacy and personal data. We would therefore like to ask you to read the respective privacy statements before you request the transfer of your personal data to certain other member companies of Deloitte Touche Tohmatsu Limited. This also applies if you instruct us to pass on your personal data to them. Deloitte Consulting GmbH assumes no liability for the content and/or data protection treatment of data that you or we leave on other websites at your request.
</p>

<h3>Protection of your personal data</h3>

<p>
Deloitte Consulting GmbH uses technologically generally accepted security standards to protect visitor data entered on the German website from misuse, loss and falsification. In addition, only certain Deloitte employees are authorised to access visitor data that is personally identifiable. These employees ensure that the confidentiality of this sensitive data is maintained within the scope of the purpose for which the data is transferred. This policy also applies to the website of other participating companies (including certain member companies of Deloitte Touche Tohmatsu Limited) and their employees, agents and affiliates to whom visitor information is disclosed in accordance with the purpose for which the information is provided, in accordance with the confidentiality policies of those companies.
</p>

<p>
All visitors to our websites are also advised that links (electronic "references") on the German website lead to other websites and information provided by third parties. Unless expressly warranted above, Deloitte Consulting GmbH does not assume any responsibility for the content of third-party websites, not even with regard to compliance with certain security standards or compliance with the basic data protection regulation.
</p>

<p>
Notwithstanding the foregoing with regard to visitor data on the German website, we use various physical, electronic and operational measures to ensure that your personal data is generally secure, accurate and up to date. These measures include, but are not limited to 
</p>

<ul>
<li>
  Education and training of the relevant employees to ensure that they are aware of data protection obligations when handling personal data,
</li>
<li>
  administrative and technical controls to limit access to personal data to persons who need to know ("need to know" principle),
</li>
<li>
  technological security measures, including firewalls, encryption and anti-virus software
</li>
<li>
  physical security measures, such as security badges for employees to access our premises
</li>
</ul>

<p>
Although we take reasonable security measures once we have received your personal information, the Transmission of data over the Internet (including by e-mail) is never completely secure. While we strive and make every effort to protect personal information, we cannot guarantee the security of any information transmitted to or from us. Please note that we have an ISO 27001 certified information security management system, which aims to ensure the optimal protection of all information processed by us.
</p>

<h3>How long do we keep your data?</h3>

<p>
We store your personal data on our systems for the longest of the following periods: (i) for as long as necessary for the activity or services in question; (ii) for a period of retention required by law; (iii) until the end of any period in which a dispute or investigation relating to the services may arise
</p>

<p>
Specifically, depending on the category of data, Deloitte will store your personal data in accordance with the applicable legal retention requirements.
</p>

<H3>Your rights</H3>


<p>
You have various rights in connection with your personal data. In particular, you have the right:
</p>

<ul>
<li>
  to update your personal data held by us or, if you think that they are inaccurate or incomplete, to request that they be corrected
</li>
<li>
  to request the deletion of your personal data held by us or a restriction on the way in which we process such data, unless this is contrary to legal obligations,
</li>
<li>
  to revoke your consent to the processing of your personal data by us at any time free of charge and without stating reasons at <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a> (provided such processing is based on consent),
</li>
<li>
  to obtain a copy of the personal data concerning you which you have provided to us in a structured, common, machine-readable format and to transmit it to another party (provided that the processing is based on consent or contract)
</li>
<li>
  to object to the processing of your personal data by us.
</li>
</ul>

<p>
In order to exercise your rights or in case of any other questions about how your personal data are processed by us, please send an e-mail to <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a> or write to us at the following address:
</p>

<p>
Deloitte Consulting GmbH<br/>
Schwannstrasse 6<br/>
40476 Düsseldorf
</p>

<h3>right of appeal</h3>

<p>
If you do not agree with the way in which your personal data is processed by us, or if you have a concern or inquiry about privacy that you have directed to us, please contact <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a>. You are also entitled to contact the data protection supervisory authority responsible for Deloitte. For an overview of the competent supervisory authorities, please refer to the corporate information of Deloitte and Deloitte.
</p> 

<h3>Changes to this privacy policy</h3>

<p>
We may change or amend this privacy policy if necessary.
</p>

<p>
To let you know when we make changes to this privacy statement, we will adjust the revision date at the top of the page. The new modified or amended Privacy Policy will be effective as of that revision date. Therefore, we encourage you to periodically review this Statement to be informed about how we process and protect your information.
</p>

<p>
Please note that the Deloitte Digital Website, including this Privacy Notice, has been made available to provide general information and guidance on specific topics, but not to address specific topics in greater depth. The Deloitte Website is not designed to provide binding advice (including on accounting, tax, legal or investment matters), any other service or work, or to answer any question related thereto. Accordingly, you may not rely on content on the Deloitte Digital website and should therefore always consult a suitably qualified advisor for questions regarding your personal finances and business.
</p>

<h3>Questions regarding the protection of privacy and personal data</h3>

<p>
If you have any questions about this declaration on the protection of privacy and personal data or if you think you have certain concerns about it which should be given special consideration, you are welcome to send us your questions and suggestions directly via <a href="mailto:kontakt@deloitte.de">kontakt@deloitte.de</a>.
</p>

<h3>Privacy policy for the use of Google Analytics & Adobe Analytics</h3>

<p>
Our websites use the web analysis service Google Analytics of Google Inc. ("Google") as well as Adobe Analytics by Adobe Systems, Inc. This involves the storage of small text files known as "cookies" on your PC by a server on the Internet, which enable an analysis of your usage behaviour on this website. The information generated and collected by the cookie is usually transferred to a Google or Adobe Analytics server in the USA where it is evaluated and stored.
</p>

<p>
If IP anonymisation is activated on this website, the IP address of Google or Adobe will be shortened beforehand within the member states of the European Union and in the other contracting states of the Agreement on the European Economic Area. The complete transfer of the IP address to the server of Google or Adobe Analytics in the USA and a shortening of the IP address there is only carried out in exceptional cases.
</p>

<p>
Google and Adobe Analytics use the above-mentioned information on behalf of the website operator in order to evaluate visitor flows and interactions on this website and to compile reports on website activities. In addition, other services related to the website and internet use are provided to the website operator.
</p>

<h3>Demographic features at Google Analytics</h3>

<p>
Our website collects information on "demographic characteristics" from Google Analytics. This provides us with information about the age, gender and interests of our site visitors. This information is collected in Analytics once retargeting and advertising reporting features are enabled in Analytics. These features can be disabled at any time through your Google Account. Click here for more information about opting out.
</p>

<p>
It is possible for you to prevent the storage of cookies on your computer by adjusting your browser software settings accordingly and thus prevent the use of Google Analytics and Adobe Analytics. However, this setting may mean that you will not be able to use all functions of this website to their full extent.
</p>

<p>
Furthermore, you have the possibility to prevent the transmission of your data about the use of this website to and the processing of this data by Google or Adobe Analytics by downloading and installing the browser plugins available under the following links: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a> or <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
</p>

<h2>II. Information on the processing of personal data pursuant to Art. 13, 14 DSGVO</h2>

<p>
In addition to the information below, please ensure that you are familiar with Deloitte's privacy policy as described above. If you have any questions of understanding or other queries, please contact us at datenschutz@deloitte.de.
</p>

<p>
Consulting Digital GmbH provides consulting services to companies and institutions from all sectors of the economy; Deloitte Consulting GmbH is a company affiliated with Deloitte GmbH Wirtschaftsprüfungsgesellschaft, which provides auditing, risk advisory, tax advisory, financial advisory and consulting services to companies and institutions from all sectors of the economy; legal advice is provided in Germany by Deloitte Legal Rechtsanwaltsgesellschaft mbH ("Deloitte Legal") (hereinafter jointly referred to as "Deloitte"/"we"). Deloitte processes personal data as part of the assignment to provide the aforementioned services, depending on the individual assignment. This information complements the above privacy policy and is subject to the following privacy policy
and provide a more detailed description of how Deloitte processes your personal data in the course of providing the commissioned service.
    </p>
      
    <p>
      Please note that this information relates exclusively to personal data within the meaning of Art. 4 No. 1 DSGVO, i.e. it does not include all data and information that Deloitte receives in connection with the underlying client relationship, but essentially only such information that relates to an identified or identifiable natural person. Notwithstanding the foregoing, the statutory and contractual confidentiality and secrecy obligations to which Deloitte and the employees of Deloitte are subject, where applicable, apply in full to all data and information which we receive from you in connection with the client relationship, regardless of whether such data and information is personal data within the meaning of the DSGVO.
    </p>
      
    <h3>responsible in the sense of DSGVO</h3>
  
    <p>
      The person responsible in accordance with Art.4 para. 7 EU Data Protection Regulation (DSGVO) for the processing of your personal data in connection with all services provided by Deloitte Consulting is
    </p>
      
    <p>
      Deloitte Consulting GmbH<br/>
      Schwannstrasse 6<br/>
      40476 Düsseldorf
    </p>
  
    <p>
      If the contractor of a contract for the performance of our services is another German Deloitte company, this company will act as the responsible body in this case. An overview of the German Deloitte companies can be found <a href="https://www2.deloitte.com/de/de/pages/about-deloitte/solutions/angaben37hgb.html">here</a>.
    </p>
  
    <h3>Data protection officer & data protection supervision</h3>
  
    <p>
      All German Deloitte companies have appointed data protection officers. You can reach the respective data protection officer at <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a>. The respective supervisory authorities can be found <a href="https://www2.deloitte.com/de/de/footerlinks1/impressum.html?icid=bottom_impressum">here</a>.
    </p>
      
    <h3>purposes of processing and legal basis for processing</h3>
  
    <p>
      Deloitte processes your personal data for the purpose of fulfilling our (pre-)contractual obligations to our customers. In this context, we process in particular your contact data such as name, address, telephone number and e-mail address for the purpose of carrying out pre-contractual measures (such as internal pre-contractual compliance checks or as part of the customer/contract annex) as well as for the performance of our respective contractual service obligations, including the administrative execution and invoicing of the respective order on the basis of Art. 6 para. 1 lit. b) DSGVO. For the management and execution of order enquiries/contracts, Deloitte uses IT systems to manage and store your personal data, but no automated decision making and profiling takes place.
    </p>
      
    <p>
      Depending on the category of documents, Deloitte stores personal data for record keeping / documentation and archiving purposes in accordance with the relevant legal requirements. 
    </p>
      
    <p>
      As a rule, Deloitte receives the necessary personal data from the customers. To this extent, Deloitte has a legitimate interest in processing this personal data pursuant to Art. 6 para. 1 lit. f) DSGVO, as Deloitte is obliged to perform the commissioned service under the underlying contract agreement. In this context, it is essential for Deloitte to process any personal data of the contact persons and contact persons of our customer (also already in the context of the preparation of the offer).
    </p>
      
    <p>
      If you yourself have commissioned Deloitte to perform certain services, such further personal data in addition to your contact details will be processed by Deloitte in the course of order processing, to the extent that this data is necessary to provide the service agreed with you and you have provided it to us. In this respect, the processing of your personal data is necessary for the performance of the contract concluded between you and Deloitte and is justified in accordance with Art. 6, Paragraph 1, Letter b) of the German Data Protection Act.
    </p>
      
    <p>
      Please note that Deloitte's General Terms and Conditions of Contract generally require the client to cooperate, to provide Deloitte with all the information required for the assignment, and to ensure that the client's requirements are met.to provide Deloitte with all documents and information necessary for the execution of the contract. In this respect, the processing of the respective order and the associated performance of the contractually agreed service by Deloitte shall not be possible or only possible to a limited extent if and to the extent that the necessary information is not provided.
    </p>
      
    <p>
      Since Deloitte is legally obliged to maintain proper records, to provide comprehensive documentation of its mandates and orders (also beyond the conclusion of an order) and to comply with further storage and documentation obligations, Deloitte also processes your personal data in the context of documents to be documented, work results or related customer-related correspondence for the purposes of record keeping, documentation and archiving, both in the form of paper files and in the context of IT systems used for this purpose, on the basis of Art. 6 para. 1 lit. c) DSGVO to fulfil our aforementioned legal obligations. 
    </p>
      
    <p>
      Irrespective of the above-mentioned purposes, Deloitte will process your contact data (in particular name, address, e-mail address) for marketing and advertising purposes, i.e. to send you information on our other offers or events. This is done on the basis of Deloitte's consent and/or a legitimate economic interest within the meaning of Art. 6 para. 1 lit. f) to inform its customers about further offers and events of its own and thus to be able to establish and maintain a long-term customer relationship. 
    </p>
      
    <p>
      Finally, Deloitte will also process your contact data for the purpose of maintaining our business contacts, if we have received them within the scope of a business event, within the scope of a business appointment (e.g. by exchanging business cards) or within the scope of an order, and transfer them to the CRM system (Customer Relationship Management System) used by us.
    </p>
      
    <p>
      Since Deloitte has a legitimate economic interest in maintaining contacts made in the course of its business dealings beyond the initial contact, in using them to establish a business relationship, and in staying in contact with the persons concerned for this purpose, the aforementioned processing of your personal data is based on Art. 6 para. 1 lit. f) DSGVO.
    </p>
      
    <h3>Categories of recipients of data and transfers to third countries</h3>
  
    <p>
      In connection with the execution of our commissioned service, personal data, as specified below, may also be transferred to third parties. In this respect, data may be transferred both to European and non-European countries and your personal data may be stored outside the EU:
    </p>
      
    <h4>To other Deloitte member companies<sup><a id="index1" routerLink="" (click)="scroll('footnote1')">1</a></sup> for the purpose of cooperation within the scope of our service provision</h4>
  
    <p>
      To the extent necessary for the provision of the commissioned service, i.e. in the case of a foreign assignment or where the expertise of a foreign colleague is required, Deloitte cooperates with other companies from the global Deloitte network. Insofar as such a transfer is made to a network company outside the EU/European Economic Area, an appropriate level of data protection is ensured by the use of standard contractual clauses of the EU Commission in the sense of Art. 46 para. 2 lit. c) DSGVO. The EU standard contractual clauses can be found at <a href="https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF">https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF</a>.
    </p>
      
    <h4>To authorities, courts or other bodies</h4>
  
    <p>
      In connection with the performance of our services, it may also be necessary to transmit information, work results and documents to authorities, courts or other public or private bodies (in the case of a foreign assignment also abroad). The same applies to cases in which Deloitte is obligated by law, official or court order to surrender/disclose personal data. This shall only occur if there are no legal obligations to secrecy.
    </p>
      
    <h4>To Deloitte internal service providers and external IT service providers</h4>
  
    <p>
      In the course of its activities, Deloitte makes use of other German or  or foreign Deloitte network companies as intra-network IT service providers that provide services for the operation, maintenance and servicing of the IT systems and applications used by the Deloitte network companies. These companies with access rights to personal data are only used if this has been agreed in the contract agreements with our clients or is legally permissible in individual cases without consent.
    </p>
    
    <p>
      Insofar as access is provided by a network company outside the European Economic Area, an adequate level of data protection is ensured by the use of standard contractual clauses of the EU Commission in the sense of Art. 46 (2) lit. c) DSGVO. 
    </p>
       
    <p>
      Specialist IT service providers used for specific mandates, e.g. for processing mandates, specialist applications and cloud services are only used in consultation with our customers where legally required.
    </p>
      
    <h3>Your rights in connection with data processing</h3>
  
    <p>
      The DSGVO essentially grants those affected the following rights, which you can assert at any time by contacting the data protection officer named in this information at <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a> or <a href="mailto:kontakt@deloitte.de">kontakt@deloitte.de</a>.
    </p>
      
    <p>
      In principle, you may at any time request information from Deloitte as to whether and what personal data about you is being processed or stored by Deloitte and how. Please note that your right to information may be limited to the extent that such information would be in conflict with professional secrecy and that confidential information would therefore be disclosed.
    </p>
      
    <p>
      In addition to your right to information, you can request the correction of your data at any time. You also have the right to have your data deleted if and to the extent that the data is no longer required for the purposes for which it was collected or, if the processing is based on your consent, but you have revoked your consent. The aforementioned right to delete your data is not applicable if your data may not be deleted due to a legal obligation or if it must be processed due to a legal obligation or if data processing is necessary for the assertion, exercise or defence of legal claims. 
    </p>
      
    <p>
      Furthermore, you have the right to request that Deloitte limit the processing of your personal data. 
    </p>
      
    <p>
      In addition, there is a right to data transferability, i.e. you can demand that Deloitte provide the data you have provided in a structured, common and machine-readable format and/or that this data be transferred to another responsible party. Please note that this does not apply if you have provided us with the data on the basis of your consent or on the basis of a contract concluded with you or if the processing is carried out using automated procedures.
    </p>
      
    <p>
      If Deloitte processes your personal data on the basis of Art. 6 para. 1 lit. f) DSGVO (e.g. if your employer, as a client of Deloitte, has provided us with your personal data as a contact person in your company, or if we use your contact data to send you information about Deloitte offers and events), you may object to this processing at any time.
    </p>
      
    <h3>Right of appeal to a data protection supervisory authority</h3>
  
    <p>
      In addition to the above-mentioned rights of data subjects, you also have the right to complain to a data protection supervisory authority pursuant to Art. 77 DSGVO if you believe that the processing of your personal data violates data protection law. In each case, the supervisory authority of the federal state in which the responsible authority is located is responsible.
    </p>
      
    <h3>Duration of data storage</h3>
  
    <p>
      Please note that Deloitte will store and process your personal data for as long as it is necessary to fulfil the above-mentioned processing purposes. Insofar as personal data is subject to statutory retention obligations or is part of documents subject to statutory retention obligations, Deloitte will store such data for the duration of the statutory retention period.
   
    </p>
      
    <p>
      Depending on the category of documents, Deloitte stores personal data on the basis of applicable legal retention requirements
    </p>
  
    <p>
      The longest retention period is decisive, if the data concerned are subject to different retention periods, and the legally required retention period can be extended depending on the individual case, if, for example, the information is required for the assertion, exercise or defence of legal claims even after the retention period has expired.
    </p>
  
    <p><small>
      <a href="" id="footnote1" (click)="scroll('index1')">1</a>) Deloitte refers to Deloitte Touche Tohmatsu Limited ("DTTL"), a private company limited by guarantee, its network of member firms and its affiliates. DTTL and each of its member companies are legally distinct and independent. DTTL (also known as "Deloitte Global") does not itself provide services to customers. A more detailed description of DTTL and its member firms can be found at <a href="www.deloitte.com/de/UeberUns">www.deloitte.com/de/UeberUns</a>.
    </small></p>
<!--
<div class="container h-100">
    <h1 class="mt-4">Privacy/Datenschutzhinweise</h1>
    <p class="mt-3">
      <b>„Datenschutzerklärung“ und „Informationen über die Verarbeitung von personenbezogenen Daten gemäß Art. 13, 14 DSGVO“</b>
    </p>
    
    <h2 class="mt-4">Einleitung</h2>
  
    <p>
      In der nachfolgenden „Datenschutzerklärung“ und in den „Informationen über die Verarbeitung von personenbezogenen Daten gemäß Art. 13, 14 DSGVO“ wird erläutert, welche Daten wir über Sie erfassen, wofür wir diese Daten benötigen und an wen wir diese Daten weitergeben. Darüber hinaus beinhalten sie auch Ihre Rechte in Bezug auf Ihre Daten und die Ansprechpartner, an die Sie sich für weitere Informationen oder Anfragen wenden können.
    </p>
    
    <p>
      Während die „Datenschutzerklärung“ in erster Linie darlegen soll, welche Daten wir über Sie verarbeiten, speichern und schützen, wenn Sie „unsere Website“ (wie unten definiert) nutzen, sollen die „Informationen über die Verarbeitung von personenbezogenen Daten gemäß Art. 13, 14 DSGVO“ im Wesentlichen die Verarbeitung Ihrer Daten im Rahmen unserer Dienstleistungen für Sie/unsere Kunden und im Rahmen aller sonstigen Aktivitäten, die Teil der Ausübung unserer Geschäftstätigkeit sind, darlegen.
    </p>
      
    <p>Letzte Überarbeitung: Mai 2019</p>
  
    <h2 class="mt-4">I. Datenschutzerklärung</h2>
  
    <h3>Für wen gilt diese Datenschutzerklärung und was wird durch sie abgedeckt?</h3>
  
    <p>
      Diese Datenschutzerklärung gilt ausschließlich für die spezifische Website der <b>deloitte.ai</b>, wofür sich die Deloitte Consulting GmbH mit Sitz in („Deloitte“, „wir“, „uns“ oder „unser“) verantwortlich zeichnen.
    </p>
  
    <p>
      Wir haben uns verpflichtet, Ihre Privatsphäre zu schützen und Ihre Daten auf eine offene und transparente Weise zu verarbeiten.
    </p>
  
    <p>
      In dieser Datenschutzerklärung wird dargelegt, wie wir Daten über Sie verarbeiten, speichern und schützen, wenn Sie unsere Website nutzen. Zudem legen wir die Verarbeitung Ihrer Daten dar, wenn wir Dienstleistungen für Sie/unsere Kunden erbringen und bei allen sonstigen Aktivitäten, die Teil der Ausübung unserer Geschäftstätigkeit sind.
    </p>
  
    <p>
      Wenn in dieser Richtlinie von „unsere Website“ oder „diese Website“ die Rede ist, so bezieht sich dies auf die spezifische Webseite auf <b>deloitte.ai</b> ist.
    </p>
  
    <p>
      Diese Datenschutzerklärung enthält zudem Informationen darüber, wann wir Ihre personenbezogenen Daten mit anderen Mitgliedern des Deloitte Netzwerks (national eigenständige Mitgliedsunternehmen oder verbundene Unternehmen von Deloitte Touche Tohmatsu Limited („DTTL“) (zusammen das „Deloitte Netzwerk“) („Über Deloitte“) und anderen Dritten (zum Beispiel unseren Dienstleistern) teilen.
    </p>
  
    <p>
      Sofern Sie uns Informationen über die Deloitte Webseite zukommen lassen, erklären Sie sich nach Art. 6 Absatz 1 Buchstabe a, 7 DSGVO (Datenschutz-Grundverordnung) einverstanden, dass wir Ihre personenbezogenen Daten im Rahmen der hier niedergelegten Grundsätze verarbeiten dürfen. Dies gilt auch für eine möglicherweise erforderliche Überlassung der personenbezogenen Daten an ausländische Mitgliedsunternehmen von Deloitte Touche Tohmatsu Limited. Bitte beachten Sie in diesem Zusammenhang, dass bestimmte Links (elektronische Verweisungen) auf der deutschen Deloitte Website zu den Websites Dritter oder anderer Mitgliedsunternehmen von Deloitte Touche Tohmatsu Limited führen, die wegen ihrer nationalen Eigenständigkeit und Unabhängigkeit sowie des dort ggfls. abweichenden Rechts nicht dem Inhalt dieser Erklärung zum Schutz der Privat- und Persönlichkeitssphäre unterliegen. Die Deloitte Consulting GmbH übernimmt keine Haftung für Inhalte und/oder die datenschutzrechtliche Behandlung von hinterlassenen Daten auf diesen anderen Websites.
    </p>
      
    <h3>Zu anderen Bereichen von deloitte.ai</h3>
  
    <p>
      Bitte beachten Sie, dass neben der <b>deloitte.ai</b> Webseite andere länderspezifische, regionale und leistungsspezifische Websites auf <b>deloitte.ai</b> angeboten werden. Diese werden von anderen Unternehmen/Einheiten innerhalb des Deloitte Netzwerks, z.B. die deloitte.com Seite von der Deloitte GmbH Wirtschaftsprüfungsgesellschaft, zur Verfügung gestellt und nicht von uns. Die Bestimmungen dieser Datenschutzerklärung gelten nicht für diese Websites sowie andere Websites, mit denen diese Website möglicherweise verlinkt ist. Wir empfehlen den Besuchern unserer Website, die Datenschutzerklärungen auf jeder dieser anderen Websites zu prüfen, bevor sie personenbezogene Informationen preisgeben.
    </p>
  
    <h3>Welche Daten werden von uns erhoben?</h3>
  
    <p>
      Obgleich die Nutzung unserer Website nicht bedingt ist durch die Übermittlung Ihrer personenbezogenen Daten und wir über die Deloitte-Website ohne Ihre Zustimmung auch keine personenbezogenen Daten speichern, verarbeiten wir ggfls. personenbezogene Daten von Ihnen, wenn Sie uns diese freiwillig für bestimmte Zwecke in den dafür ausgewiesenen Bereichen unserer Website zur Verfügung stellen.
    </p>
  
    <p>
      Im Rahmen der Erbringung von Dienstleistungen für Sie/unsere Kunden und der Durchführung erforderlicher Vorab-Prüfungen im Zusammenhang mit unseren Dienstleistungen (z.B. regulatorisch geforderte Conflict- oder „Know-your-Client“- Checks) oder im Rahmen der Besprechung möglicher von uns zu erbringender Dienstleistungen verarbeiten wir soweit erforderlich personenbezogene Daten über Sie.
    </p>
  
    <p>
      Wir können diese Daten erheben oder erhalten, weil Sie sie uns zur Verfügung stellen (zum Beispiel auf einem Formular auf unserer Website), weil wir sie von anderen Personen erhalten haben (zum Beispiel Ihrem Arbeitgeber oder Berater oder von Drittanbietern, die wir im Rahmen des gesetzlich Zulässigen zur Unterstützung bei der Ausübung unserer Geschäftstätigkeit beauftragt haben) oder weil sie öffentlich verfügbar sind.
    </p>
  
    <p>
      Um die Bedienung unserer Websites für Sie zu verbessern und ihre effektive Funktionalität sicherzustellen, setzen wir Cookies (kleine Textdateien, die im Browser des Benutzers angeboten/gespeichert werden) und Web-Beacons (kleine Bilddateien, die es der Deloitte Webseite erlauben, die Anzahl der Besucher für einen bestimmten Bereich der Deloitte Webseite festzustellen und bestimmte abgelegte Cookies anzufordern) ein. Wir setzen diese Instrumente ein, um Nutzerdaten wie etwa IP-Adresse, die Domain, den Browsertyp, Sprache, Zugriffszeiten und die besuchten Seiten auf unserer Webseite zu ermitteln. Diese gesammelten Informationen werden an unsere Webmaster weitergeleitet, um im Ergebnis sicherzustellen, dass unsere Webseite eine nützliche und effektive Informationsquelle bleibt.
    </p>
  
    <p>
      Weder unsere Cookies noch unsere Web Beacons sammeln persönliche Daten wie etwa Namen oder E-Mail-Adressen. Um zu verhindern, dass Ihr Internetbrowser Cookies bzw. Web Beacons überhaupt empfängt, sollten Sie Ihren Internetbrowser so einstellen, dass dieser entweder gar keine Cookies bzw. Web Beacons mehr empfängt oder Sie bei jedem einzelnen Cookie bzw. Web Beacons auffordert, Ihre Zustimmung zum Empfang zu geben. Lesen Sie unsere Cookie Notice, um weitere Informationen zur Abschaltung dieser Instrumente zu erhalten. Unter Umständen kann es aber vorkommen, dass die DeloitteDigital Webseite den Empfang eines Cookies und/oder Web Beacons erforderlich macht und ansonsten Besuchern den Zugang zu bestimmten Bereichen verwehrt.
    </p>
  
    <p>
      Sie können mittels opt-out verhindern, dass Ihre anonymisierten Internet-Aktivitäten auf Webseiten durch Analyse Cookies aufgezeichnet werden. Wir nutzen die folgenden Dienste-Anbieter : Google Analytics & Adobe Analytics (wie unten beschrieben). Mehr Informationen über deren Datenschutzrichtlinien, wie Sie die Opt-out-Funktion verwenden können sowie zusätzliche Informationen über Cookies erfahren Sie durch einen Klick auf die folgenden Links:
    </p>
    
    <p>Adobe: <a href="http://www.adobe.com/de/privacy/opt-out.html">http://www.adobe.com/de/privacy/opt-out.html</a></p>
  
    <h3>Deloitte Cookie-Hinweis</h3>
  
    <p>
      Die von uns über Sie erhobenen oder erlangten personenbezogenen Daten können unter anderem Folgende sein: Name; Alter, Geburtsdatum; Geschlecht; E-Mail-Adresse; Geburtsland; Angaben zu Arbeit und Ausbildung (zum Beispiel bei welchem Unternehmen Sie arbeiten, Ihre Tätigkeitsbezeichnung und Angaben zu Ihrer Ausbildung); finanzielle und steuerliche Informationen (zum Beispiel wo Sie zu Einkommens- und Steuerzwecken ansässig sind); Ihre Beiträge in Blogs, Foren, Wikis und allen sonstigen von uns bereitgestellten Social Media-Applikationen und –Dienstleistungen; Ihre IP-Adresse; Ihren Browsertyp und Ihre Browsersprache; Ihre Zugriffszeiten; Beschwerdedetails; Details darüber, wie Sie unsere Produkte und Dienstleistungen verwenden; Einzelheiten dazu, wie Sie am liebsten mit uns interagieren und sonstige vergleichbare Informationen.
    </p>
  
    <p>
      Die von uns verarbeiteten personenbezogenen Daten können ebenfalls sogenannte „sensible“ oder „besondere Kategorien“ von personenbezogenen Daten enthalten, soweit Sie uns diese freiwillig übermitteln. Die von uns verarbeiteten Arten von personenbezogenen Daten und besonderen Kategorien von personenbezogenen Daten können in Abhängigkeit von der Art der von uns erbrachten Dienstleistungen für Sie/unseren Kunden oder der Art, wie Sie unsere Website nutzen, variieren.
    </p>
  
    <p>
      Wir unterstreichen die Bedeutung des Schutzes der Privat- und Persönlichkeitssphäre von Minderjährigen (explizit Kindern unter 13 Jahren) insbesondere mit Rücksicht auf einen möglichen Missbrauch der heutigen Internetkommunikation. Wir möchten aus diesem Grund hervorheben, dass unsere Website und Dienstleistungen nicht für Kinder konzipiert oder absichtlich auf Minderjährige ausgerichtet sind. Wir erfassen oder speichern wissentlich keine Informationen über Minderjährige./p>
    </p>
      
    <h3>Wie werden Ihre Daten von uns verarbeitet?</h3>
      
    <h4>Verarbeitung von über unsere Website erhobenen personenbezogenen Daten</h4>
    
    <p>
      Neben den oben genannten Zwecken im Zusammenhang mit der Ausübung unserer Geschäftstätigkeit können wir Ihre über unsere Website erhobenen personenbezogenen Daten auch verarbeiten:
    </p>
  
    <ul>
      <li>
        um unsere Website zu verwalten und zu verbessern,
      </li>
      <li>
        um den Inhalt unserer Website anzupassen, um Ihnen ein individuelles Nutzererlebnis zu bieten und Ihre Aufmerksamkeit auf Informationen zu unseren Produkten und Dienstleistungen zu lenken, die für Sie von Interesse sein können,
      </li>
      <li>
        um alle Anfragen, die Sie über unsere Website an uns richten, zu verwalten und zu beantworten,
      </li>
      <li>
        zu den Zwecken, zu denen der Besucher im Sinne von Art. 6 Absatz 1 Buchstabe a, 7 DSGVO freiwillig zugestimmt hat, wie etwa im Rahmen einer Bewerbung oder bei einer Registrierung zum Erhalt von allgemeinen oder exklusiven Informationen, sowie zu Zwecken, mit denen registrierte Benutzer nach den Umständen der Webseitenbenutzung rechnen konnten.
      </li>
    </ul>
  
    <p>
      Sofern Sie als registrierter Besucher die angebotenen Informationen bzw. sonstige Bereiche nicht mehr nutzen wollen, können Sie jederzeit einer weiteren Verarbeitung Ihrer personenbezogenen Daten für die Zukunft widersprechen. Bitte wenden Sie sich hierzu an die Deloitte Consulting GmbH als Verantwortlicher i.S.d. DSGVO, Schwannstraße 6, 40476 Düsseldorf oder an <a href="mailto:kontakt@deloitte.de">kontakt@deloitte.de</a>. Für den Widerspruch entstehen keine anderen als die Übermittlungskosten nach den Basistarifen.
    </p>
  
    <h4>Verarbeitung personenbezogener Daten zur Erbringung von Dienstleistungen gegenüber unseren Kunden</h4>
  
    <p>
      Wir verarbeiten Ihre personenbezogenen Daten zum Zwecke der Durchführung individueller Vertragsbeziehungen, zur Erbringung von Dienstleistungen für Sie/unseren Kunden. In diesem Rahmen können wir Ihre personenbezogenen Daten im Laufe eines Schriftwechsels zu den Dienstleistungen verarbeiten. Ein solcher Schriftwechsel kann mit Ihnen, unserem Kunden, sonstigen Mitgliedern des Deloitte Netzwerks, unseren Dienstleistern oder zuständigen Behörden stattfinden. Ebenso können wir Ihre personenbezogenen Daten zur Durchführung erforderlicher Vorab-Prüfungen im Zusammenhang mit unseren Dienstleistungen (z.B. Conflict- oder „Know-your-Client“- Checks) oder im Rahmen der Besprechung möglicher von uns zu erbringender Dienstleistungen verarbeiten.
    </p>
  
    <p>
      Da wir unseren Kunden ein großes Angebot an Dienstleistungen anbieten, variiert auch die Art und Weise, wie wir personenbezogene Daten in Bezug auf unsere Dienstleistungen verarbeiten. Beispielsweise können wir personenbezogene Daten:
    </p>
  
    <ul><li>
      über die Mitarbeiter und Vertragspartner eines Kunden während der Durchführung einer Software-Implementierung (oder einer ähnlichen Aktivität) für einen Kunden verarbeiten
    </li></ul>
    
    <h4>Verarbeitung personenbezogener Daten für sonstige Aktivitäten, die Teil der Ausübung unserer Geschäftstätigkeit sind</h4>
  
    <p>
      Wir können Ihre personenbezogenen Daten auch im Zusammenhang mit folgendem, mit dem ursprünglichen Zweck zusammenhängendem Zweck verarbeiten (je nach Erforderlichkeit und berufsrechtlicher Zulässigkeit im Einzelfall):
    </p>
  
    <ul>
      <li>
        geltende gesetzliche oder aufsichtsrechtliche Verpflichtungen,
      </li>
      <li>
        Anfragen und Mitteilungen von zuständigen Behörden im Rahmen einschlägiger berufsrechtlicher Verschwiegenheitspflichten,
      </li>
      <li>
        Zwecke der Rechnungslegung, Rechnungsstellung und Risikoanalyse,
      </li>
      <li>
        Zwecke des Kundenverhältnisses, unter anderem: (i) Zusendungen von Thought Leadership oder Angaben zu unseren Produkten und Dienstleistungen, von denen wir glauben, dass sie für Sie von Interesse sind; (ii) Kontaktaufnahme mit Ihnen zur Einholung von Feedback zu unseren Dienstleistungen; und (iii) Kontaktaufnahme mit Ihnen für sonstige Markt- oder Forschungszwecke, soweit hierfür die besonderen gesetzlichen Voraussetzungen gegeben sind;
      </li>
      <li>
        Dienstleistungen, die wir von unseren Fachberatern wie z. B. Anwälten, Wirtschaftsprüfern und Consultants erhalten,
      </li>
      <li>
        administrative Zwecke im Zusammenhang mit der konkreten Geschäftstätigkeit,
      </li>
      <li>
        Schutz unserer Rechte und der unserer Kunden.
      </li>
    </ul>
    
    <h3>Rechtsgrundlagen zur Verarbeitung personenbezogener Daten</h3>
  
    <p>
      Wir verarbeiten Ihre personenbezogenen Daten für die oben genannten Zwecke auf Basis folgender Rechtsgrundlagen: (a) aufgrund unseres legitimen Interesses an der effektiven Erbringung unserer Dienstleistungen gegenüber Ihnen und unseren Kunden; (b) aufgrund unseres legitimen Interesses an der effektiven und rechtmäßigen Ausübung unserer Geschäftstätigkeit, sofern Ihre Interessen nicht gegenüber diesem Interesse überwiegen; (c) aufgrund der für uns geltenden gesetzlichen Pflichten(d) weil die Daten zur Erbringung unserer Dienstleistungen gegenüber Ihnen/unserem Kunden erforderlich sind.
    </p>
  
    <p>
      Soweit wir sensible personenbezogene Daten in Bezug auf Sie für einen der oben genannten Zwecke verarbeiten, tun wir dies entweder weil: (i) Sie uns Ihre ausdrückliche Einwilligung zur Verarbeitung dieser Daten erteilt haben; (ii) wir gesetzlich zur Verarbeitung dieser Daten verpflichtet sind, um sicherzustellen, dass wir unseren „Know your Client-“ und „Anti-Geldwäsche“-Verpflichtungen nachkommen (oder sonstigen für uns geltenden gesetzlichen Pflichten); (iii) die Verarbeitung zur Erfüllung unserer arbeits-, sozialversicherungs- oder sozialschutzrechtlichen Pflichten erforderlich ist; (iv) die Verarbeitung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist oder (v) Sie die Daten öffentlich gemacht haben.
    </p>
  
    <p>
      Sofern wir gesetzlich verpflichtet sind, Ihre ausdrückliche Einwilligung zu erlangen, um Ihnen bestimmte Werbematerialien anzubieten, werden wir Ihnen diese Materialien nur dann anbieten, wenn wir eine solche Einwilligung von Ihnen erhalten haben. Wenn Sie keine weiteren Werbematerialien mehr von uns erhalten möchten, können Sie die Abmeldefunktion in der Mitteilung anklicken oder eine E-Mail an <a href="mailto:kontakt@deloitte.de">kontakt@deloitte.de</a> schicken und der Verarbeitung Ihrer personenbezogenen Daten für diese Zwecke jederzeit ohne Angabe von Gründen widersprechen. Für den Widerspruch entstehen keine anderen als die Übermittlungskosten nach den Basistarifen.
    </p>
  
    <h3>An wen geben wir Ihre Daten weiter?</h3>
  
    <p>
      Im Zusammenhang mit einem oder mehreren der im Abschnitt „Wie werden Ihre Daten von uns verarbeitet?“ dargestellten Zwecke können wir Einzelheiten über Sie an folgende Personen weitergeben: andere Mitglieder des Deloitte Netzwerks; Dritte, die für uns und/oder das Deloitte Netzwerk Dienstleistungen erbringen; zuständige Behörden (einschließlich Gerichten und uns oder andere Mitglieder des Deloitte Netzwerks beaufsichtigenden Behörden); Ihren Arbeitgeber und/oder dessen Berater; Ihre Berater; Organisationen, die uns bei der Identifizierung von Betrugsfällen unterstützen sowie andere Dritte, die berechtigterweise aus einem oder mehreren der im Abschnitt „Wie werden Ihre Daten von uns verarbeitet?“ dargestellten Zwecke Zugriff auf Sie bezogene personenbezogene Daten verlangen. In jedem Fall erfolgt eine Weitergabe nur dann, wenn dies auch unter Berücksichtigung einschlägiger Verschwiegenheitspflichten zulässig ist.
    </p>
      
    <p>
      Unsere Website hostet unterschiedliche Blogs, Foren, Wikis und andere Social Media-Applikationen oder –Dienstleistungen , die es Ihnen ermöglichen, Inhalte mit anderen Nutzern zu teilen (zusammengefasst „Social Media-Applikationen“). Seien Sie sich bei der Nutzung dieser Social- Media-Applikationen bitte jedoch stets bewusst, dass die über diesen Informationskanal verbreiteten Informationen (auch) von anderen Nutzern der Anwendung gelesen, erhoben, gespeichert und/oder genutzt werden können. Wir haben wenig oder keine Kontrolle über diese anderen Nutzer und können daher nicht garantieren, dass alle von Ihnen in den Social Media-Applikationen beigetragenen Informationen in Übereinstimmung mit dieser Datenschutzerklärung gehandhabt werden. Für diese Personen und den Umgang dieser Personen mit Ihren (persönlichen) Daten übernehmen wir deshalb keine Verantwortung.
    </p>
      
    <p>
      Bitte beachten Sie, dass einige der oben genannten Empfänger Ihrer personenbezogenen Daten in Ländern außerhalb der Europäischen Union ansässig sein können, deren Datenschutzgesetze möglicherweise weniger umfassend sind. In diesen Fällen stellen wir sicher, dass angemessene Sicherheitsvorkehrungen getroffen wurden, um Ihre personenbezogenen Daten zu schützen, die mit unseren gesetzlichen Pflichten übereinstimmen. Wenn der Empfänger kein Mitglied des Deloitte Netzwerks ist, kann eine angemessene Sicherheitsvorkehrung eine Datenübermittlungsvereinbarung mit dem Empfänger basierend auf von der Europäischen Kommission anerkannten Standardvertragsklauseln für die Übermittlung von personenbezogenen Daten an Drittländer sein.
    </p>
      
    <p>
      Weitere Einzelheiten zu den oben beschriebenen Übermittlungen und den von Deloitte implementierten angemessenen Sicherheitsvorkehrungen in Bezug auf diese Übermittlungen sind auch bei uns erhältlich. Bitte wenden Sie sich dazu an <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a>.
    </p>
      
    <p>
      Wir müssen Ihre personenbezogenen Daten möglicherweise auch weitergeben, wenn wir dazu per Gesetz, durch eine Aufsichtsbehörde oder im Rahmen eines rechtlichen Verfahrens verpflichtet sind. 
    </p>
      
    <p>
      Wir sind berechtigt, nicht-personenbezogene, anonymisierte und zusammengefasste Daten mit Dritten für verschiedene Zwecke, u. a. Datenanalyse, Forschung, Angebotserstellung, Thought Leadership und Werbezwecke, zu teilen.
    </p>
      
    <p>
      Bitte beachten Sie, falls Sie Daten an andere Mitgliedsunternehmen von Deloitte Touche Tohmatsu Limited übermitteln, dass die einzelnen Mitgliedsunternehmen national eigenständig und unabhängig sind, in der Regel einem von der DSGVO abweichenden Recht unterliegen und gegebenenfalls eine hiervon abweichende Erklärung zum Schutz der Privat- und Persönlichkeitssphäre abgegeben haben. Wir möchten Sie deshalb insbesondere vor einer von Ihnen angestrebten Weiterleitung Ihrer personenbezogenen Daten an bestimmte andere Mitgliedsunternehmen von Deloitte Touche Tohmatsu Limited bitten, sich die jeweiligen Erklärungen zum Schutz der Privat- und Persönlichkeitssphäre (Privacy Statements) durchzulesen. Dies gilt auch, sofern Sie uns beauftragen, Ihre personenbezogenen Daten an diese weiterzuleiten. Die Deloitte Consulting GmbH übernimmt keine Haftung für Inhalte und/oder die datenschutzrechtliche Behandlung von Daten, die Sie bzw. wir auf Ihren Wunsch hin auf anderen Webseiten hinterlassen.
    </p>
      
    <h3>Schutz Ihrer personenbezogenen Daten</h3>
  
    <p>
      Die Deloitte Consulting GmbH verwendet zur Sicherung eingegebener Besucherdaten technologisch allgemein anerkannte Sicherheitsstandards, um Besucherdaten auf der deutschen Webseite vor Missbrauch, Verlust und Verfälschung zu schützen. Zudem sind nur bestimmte Mitarbeiter von Deloitte zu den als persönlich identifizierbaren Besucherdaten zugangsberechtigt. Diese Mitarbeiter stellen im Rahmen des Überlassungszweckes sicher, dass die Vertraulichkeit dieser sensiblen Daten gewahrt wird. Dieser Grundsatz gilt nach den entsprechend abgegebenen Vertraulichkeitserklärungen der anderen teilnehmenden Gesellschaften (inklusive bestimmter Mitgliedsunternehmen von Deloitte Touche Tohmatsu Limited) auch für deren Webseite und damit auch für von diesen betrauten Mitarbeitern, Vertretern und verbundenen Unternehmen, an die Besucherdaten im Rahmen des Überlassungszweckes weitergegeben werden.
    </p>
      
    <p>
      Alle Besucher unserer Websites seien auch darauf hingewiesen, dass Links (elektronische "Verweisungen") auf der deutschen Webseite zu anderen Webseiten und Informationen Dritter führen. Sofern nicht ausdrücklich oben zugesichert, übernimmt Deloitte Consulting GmbH für Inhalte auf den Webseiten Dritter keinerlei Verantwortung, auch nicht bezogen auf die Einhaltung bestimmter Sicherheitsstandards oder die Einhaltung der Datenschutz-Grundverordnung.
    </p>
      
    <p>
      Ungeachtet der vorstehenden Ausführungen zu den Besucherdaten auf der deutschen Website verwenden wir verschiedene physikalische, elektronische und betriebliche Maßnahmen, um sicherzustellen, dass Ihre personenbezogenen Daten allgemein sicher, zutreffend und auf dem aktuellen Stand sind. Zu diesen Maßnahmen gehören u. a.: 
    </p>
      
    <ul>
      <li>
        Ausbildung und Schulungen der entsprechenden Mitarbeiter, um sicherzustellen, dass sie sich beim Umgang mit personenbezogenen Daten der Datenschutzpflichten bewusst sind,
      </li>
      <li>
        administrative und technische Kontrollen zur Einschränkung des Zugriffs auf personenbezogene Daten auf Personen, die davon Kenntnis haben müssen („Need to Know“-Prinzip),
      </li>
      <li>
        technologische Sicherheitsmaßnahmen, einschließlich Firewalls, Verschlüsselungen und Anti-Viren-Software,
      </li>
      <li>
        physikalische Sicherheitsmaßnahmen, wie Sicherheitsausweise für Mitarbeiter zum Zugang zu unseren Räumlichkeiten.
      </li>
    </ul>
    
    <p>
      Obwohl wir angemessene Sicherheitsmaßnahmen anwenden, sobald wir Ihre personenbezogenen Daten empfangen haben, ist die Übertragung von Daten über das Internet (einschließlich per E-Mail) niemals vollkommen sicher. Wir sind bestrebt und setzen alles daran, personenbezogene Daten zu schützen, können die Sicherheit der an oder von uns übermittelten Daten jedoch nicht garantieren. Bitte beachten Sie, dass wir über ein ISO 27001 zertifiziertes Informationssicherheitsmanagementsystem verfügen, welches den optimalen Schutz aller bei uns verarbeiteten Informationen zum Ziel hat.
    </p>
  
    <h3>Wie lange bewahren wir Ihre Daten auf?</h3>
  
    <p>
      Wir speichern Ihre personenbezogenen Daten auf unseren Systemen für den längsten der folgenden Zeiträume: (i) so lange, wie es für die jeweilige Aktivität oder die jeweiligen Dienstleistungen erforderlich ist; (ii) für einen gesetzlich vorgeschriebenen Aufbewahrungszeitraum; (iii) bis zum Ende des Zeitraums, in dem ein Rechtsstreit oder Ermittlungen hinsichtlich der Dienstleistungen aufkommen könnte/n
    </p>
  
    <p>
      Im Einzelnen speichert Deloitte je nach Kategorie der Daten Ihre personenbezogenen Daten im Einklang mit den jeweils anwendbaren gesetzlichen Aufbewahrungspflichten.
    </p>
      
    <h3>Ihre Rechte</h3>
  
    <p>
      Sie haben verschiedene Rechte im Zusammenhang mit Ihren personenbezogenen Daten. Insbesondere haben Sie das Recht:
    </p>
    
    <ul>
      <li>
        eine Aktualisierung Ihrer von uns aufbewahrten personenbezogenen Daten oder, falls Sie denken, diese sind unzutreffend oder unvollständig, eine Korrektur dieser Daten zu fordern,
      </li>
      <li>
        die Löschung Ihrer von uns aufbewahrten personenbezogenen Daten oder eine Beschränkung der Art und Weise, wie wir diese Daten verarbeiten, zu fordern, soweit dem nicht gesetzliche Pflichten entgegenstehen,
      </li>
      <li>
        Ihre Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten durch uns jederzeit kostenfrei ohne Angabe von Gründen unter <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a> zu widerrufen (sofern eine solche Verarbeitung auf Grundlage einer Einwilligung erfolgt),
      </li>
      <li>
        eine Kopie der Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und sie einer anderen Partei zu übermitteln (sofern die Verarbeitung auf Grundlage einer Einwilligung oder eines Vertrags erfolgt),
      </li>
      <li>
        der Verarbeitung Ihrer personenbezogenen Daten durch uns zu widersprechen.
      </li>
    </ul>
  
    <p>
      Zur Ausübung Ihrer Rechte oder bei sonstigen Fragen dazu, wie Ihre personenbezogenen Daten von uns verarbeitet werden, senden Sie bitte eine E-Mail an <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a> oder schreiben Sie uns unter folgender Adresse:
    </p>
      
    <p>
      Deloitte Consulting GmbH<br/>
      Schwannstraße 6<br/>
      40476 Düsseldorf
    </p>
  
    <h3>Beschwerderecht</h3>
  
    <p>
      Wenn Sie mit der Art und Weise, wie Ihre personenbezogenen Daten von uns bearbeitet werden, oder einem Anliegen oder einer Anfrage zum Datenschutz, das/die Sie an uns gerichtet haben, nicht einverstanden sind, kontaktieren Sie bitte <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a>. Sie sind zudem berechtigt, sich an die für Deloitte zuständige Datenschutzaufsicht zu wenden. Eine Übersicht über die zuständigen Aufsichtsbehörden finden Sie in den Unternehmensangaben von Deloitte und Deloitte.
    </p> 
  
    <h3>Änderungen dieser Datenschutzerklärung</h3>
  
    <p>
      Wir können diese Datenschutzerklärung bei Bedarf ändern oder ergänzen.
    </p>
  
    <p>
      Um Sie darüber in Kenntnis zu setzen, wann wir Änderungen an dieser Datenschutzerklärung vornehmen, werden wir das Überarbeitungsdatum am Seitenanfang anpassen. Die neue geänderte oder ergänzte Datenschutzerklärung gilt ab diesem Überarbeitungsdatum. Daher empfehlen wir Ihnen, diese Erklärung regelmäßig zu prüfen, um sich darüber zu informieren, wie wir Ihre Daten verarbeiten und schützen.
    </p>
      
    <p>
      Bitte beachten Sie, dass die Deloitte Digital Websiteeinschließlich dieser Datenschutzhinweise verfügbar gemacht wurden, um allgemeine Informationen und Hinweise zu bestimmten Themen wiederzugeben, jedoch nicht, um einzelne Themen vertieft zu behandeln. Die Deloitte Website ist nicht dafür konzipiert, einen verbindlichen Rat (auch nicht zu den Themen Bilanzierung, Steuern, Recht, Investitionen), eine sonstige Dienst- oder Werkleistung oder etwa eine Antwort auf eine damit im Zusammenhang stehende Frage zu geben. Dementsprechend können Sie sich für eine Entscheidung oder Maßnahme nicht auf Inhalte der Deloitte Digital Website stützen und sollten sich deshalb bei Fragen Ihrer persönlichen Finanzen und Geschäfte stets an einen entsprechend fachlich qualifizierten Berater wenden.
    </p>
  
    <h3>Fragen zum Schutz der Privat- und Persönlichkeitssphäre</h3>
  
    <p>
      Wenn Sie Fragen zu dieser Erklärung zum Schutz der Privat- und Persönlichkeitssphäre haben oder meinen, bestimmte Bedenken hiergegen zu haben, die besonders berücksichtigt werden sollten, können Sie Ihre Fragen und Anregungen gerne und unmittelbar über <a href="mailto:kontakt@deloitte.de">kontakt@deloitte.de</a> an uns richten.
    </p>
  
    <h3>Datenschutzerklärung für die Nutzung von Google Analytics & Adobe Analytics</h3>
  
    <p>
      Unsere Websites benutzen den Webanalysedienst Google Analytics der Google Inc. („Google“) sowie Adobe Analytics von Adobe Systems, Inc. Dabei werden kleine Textdateien sog. „Cookies“ durch einen Server im Internet auf Ihrem PC gespeichert, die eine Analyse Ihres Nutzungsverhaltens auf dieser Website ermöglichen. Die durch den Cookie erzeugten und gesammelten Informationen werden in der Regel an einen Google- bzw. Adobe-Analytics-Server in den USA transferiert und dort ausgewertet und gespeichert.
    </p>
      
    <p>
      Wird die IP-Anonymisierung auf dieser Website aktiviert, so wird die IP-Adresse von Google bzw. Adobe zuvor innerhalb der Mitgliedstaaten der Europäischen Union und in den anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Die vollständige Übertragung der IP-Adresse an den Server von Google bzw. Adobe Analytics in den USA und eine dortige Kürzung der IP-Adresse erfolgt nur in Ausnahmefällen.
    </p>
      
    <p>
      Google bzw. Adobe Analytics nutzen die oben genannten Informationen im Auftrag des Website-Betreibers, um die Besucherströme und Interaktionen auf dieser Website auszuwerten und Berichte über die Websiteaktivitäten zusammenzustellen. Des Weiteren werden gegenüber dem Website-Betreiber weitere mit der Website- und der Internetnutzung im Zusammenhang stehende Dienstleistungen erbracht.
    </p>
  
    <h3>Demografische Merkmale bei Google Analytics</h3>
  
    <p>
      Unsere Website erfasst Informationen zu “demografischen Merkmalen” von Google Analytics. Dabei erhalten wir Informationen über Alter, Geschlecht und Interessen unserer Seitenbesucher. Diese Informationen werden in Analytics erfasst, sobald Retargeting und Funktionen für Werbeberichte in Analytics aktiviert sind. Diese Funktionen lassen sich jederzeit über Ihr Google-Konto deaktivieren. Hier finden Sie weitere Informationen zum Opt-Out.
    </p>
      
    <p>
      Es besteht die Möglichkeit, dass Sie durch eine entsprechende Einstellung Ihrer Browser-Software die Speicherung der Cookies auf Ihrem Computer verhindern und damit den Einsatz von Google Analytics und Adobe Analytics unterbinden. Allerdings kann diese Einstellung gegebenenfalls dazu führen, dass Sie nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
    </p>
      
    <p>
      Darüber hinaus haben Sie die Möglichkeit, die Übertragung Ihrer Daten über die Nutzung dieser Website an und die Verarbeitung dieser Daten durch Google bzw. Adobe Analytics zu verhindern, indem Sie die unter den folgenden Links verfügbaren Browser-Plugins herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a> bzw. <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
    </p>
      
    <h2>II. Informationen über die Verarbeitung von personenbezogenen Daten gemäß Art. 13, 14 DSGVO</h2>
  
    <p>
      Bitte stellen Sie sicher, dass Sie sich zusätzlich zu den nachfolgenden Informationen auch mit der vorstehenden Datenschutzerklärung von Deloitte vertraut machen. Bei Verständnisfragen oder sonstigen Rückfragen kontaktieren Sie uns gerne unter datenschutz@deloitte.de.
    </p>
      
    <p>
      Die Consulting Digital GmbH erbringt Dienstleistungen in den Bereichen Consulting für Unternehmen und Institutionen aus allen Wirtschaftszweigen; Deloitte Consulting GmbH ist ein mit der Deloitte GmbH Wirtschaftsprüfungsgesellschaft verbundenes Unternehmen, welche Dienstleistungen in den Bereichen Wirtschaftsprüfung, Risk Advisory, Steuerberatung, Financial Advisory und Consulting für Unternehmen und Institutionen aus allen Wirtschaftszweigen erbringt; Rechtsberatung wird in Deutschland von der Deloitte Legal Rechtsanwaltsgesellschaft mbH („Deloitte Legal“) erbracht (nachfolgend gemeinsam „Deloitte“/“wir“). Im Rahmen der Beauftragung zur Erbringung vorgenannter Dienstleistungen verarbeitet Deloitte je nach Einzelauftrag personenbezogene Daten. Diese Informationen ergänzen die vorstehende Datenschutzerklärung und stellen eine konkretisierte Beschreibung dessen dar, wie Deloitte Ihre personenbezogenen Daten im Rahmen der Erbringung der beauftragten Dienstleistung verarbeitet.
    </p>
      
    <p>
      Bitte beachten Sie, dass sich diese Informationen ausschließlich auf personenbezogene Daten im Sinne des Art. 4 Nr. 1 DSGVO beziehen, d.h. nicht sämtliche Daten und Informationen umfassen, die Deloitte im Zusammenhang mit dem jeweils zugrundeliegenden Mandatsverhältnis erhält, sondern im Wesentlichen nur solche Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Ungeachtet dessen gelten die gesetzlichen und vertraglichen Geheimhaltungs- und Verschwiegenheitspflichten, denen Deloitte und die bei Deloitte beschäftigten Mitarbeiter unterliegen, soweit anwendbar, vollumfänglich für alle Daten und Informationen, die wir von Ihnen im Rahmen des Mandatsverhältnisses erhalten, unabhängig davon, ob es sich dabei um personenbezogene Daten im Sinne der DSGVO handelt.
    </p>
      
    <h3>Verantwortlicher im Sinne der DSGVO</h3>
  
    <p>
      Verantwortlicher im Sinne des Art.4 Abs. 7 EU Datenschutzgrundverordnung (DSGVO) für die Verarbeitung Ihrer personenbezogenen Daten im Zusammenhang mit allen Leistungen, die von Deloitte Consulting erbracht werden, ist die:
    </p>
      
    <p>
      Deloitte Consulting GmbH<br/>
      Schwannstraße 6<br/>
      40476 Düsseldorf
    </p>
  
    <p>
      Ist Auftragnehmer eines Vertrages zur Durchführung unserer Leistungen eine andere deutsche Deloitte Gesellschaft, agiert diese in dem Fall als verantwortliche Stelle. Eine Übersicht der deutschen Deloitte Gesellschaften finden Sie <a href="https://www2.deloitte.com/de/de/pages/about-deloitte/solutions/angaben37hgb.html">hier</a>.
    </p>
  
    <h3>Datenschutzbeauftragter & Datenschutzaufsicht</h3>
  
    <p>
      Alle deutschen Deloitte Gesellschaften haben Datenschutzbeauftragte bestellt. Sie erreichen den jeweiligen Datenschutzbeauftragten unter <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a>. Die jeweils zuständigen Aufsichtsbehörden finden Sie <a href="https://www2.deloitte.com/de/de/footerlinks1/impressum.html?icid=bottom_impressum">hier</a>.
    </p>
      
    <h3>Zwecke der Verarbeitung und Rechtsgrundlage für die Verarbeitung</h3>
  
    <p>
      Deloitte verarbeitet Ihre personenbezogenen Daten zum Zwecke der Erfüllung unserer (vor-) vertraglichen Verpflichtung gegenüber unseren Kunden. In diesem Zusammenhang verarbeiten wir insbesondere Ihre Kontaktdaten wie Name, Anschrift, Telefonnummer und E-Mail-Adresse zur Durchführung von vorvertraglichen Maßnahmen (wie interner vorvertraglicher Compliance-Prüfungen oder im Rahmen der Kunden/-Vertragsanlage) sowie zur Durchführung unserer jeweiligen vertraglichen Leistungspflichten einschließlich der administrativen Durchführung und Abrechnung des jeweiligen Auftrags auf der Grundlage von Art. 6 Abs. 1 lit. b) DSGVO. Zum Management und der Durchführung von Auftragsanfragen/Aufträgen nutzt Deloitte IT-Systeme zur Verwaltung und Speicherung Ihrer personenbezogenen Daten, wobei jedoch keine automatisierte Entscheidungsfindung und kein Profiling stattfindet.
    </p>
      
    <p>
      Je nach Kategorie der Dokumente speichert Deloitte personenbezogene Daten zur Aktenführung/Dokumentation und zu Archivierungszwecken im Einklang mit den einschlägigen gesetzlichen Vorschriften. 
    </p>
      
    <p>
      Im Regelfall erhält Deloitte die erforderlichen personenbezogenen Daten von den Kunden. Insofern besteht für Deloitte gemäß Art. 6 Abs. 1 lit. f) DSGVO ein berechtigtes Interesse an der Verarbeitung dieser personenbezogenen Daten, da Deloitte aus der zugrundeliegenden Auftragsvereinbarung zur Durchführung der beauftragten Dienstleistung verpflichtet ist. In diesem Zusammenhang ist es für Deloitte unerlässlich, etwaige personenbezogene Daten der Kontaktpersonen und Ansprechpartner unseres Kunden (auch bereits im Rahmen der Angebotserstellung) zu verarbeiten.
    </p>
      
    <p>
      Sofern Sie selbst Deloitte zur Durchführung bestimmter Dienstleistungen beauftragt haben, werden über Ihre Kontaktdaten hinaus solche weiteren personenbezogenen Daten von Ihnen im Rahmen der Auftragsbearbeitung verarbeitet, soweit diese für die Erbringung der mit Ihnen vereinbarten Dienstleistung erforderlich sind und Sie uns diese übermittelt haben. Insofern ist die Verarbeitung Ihrer personenbezogenen Daten für die Erfüllung des zwischen Ihnen und Deloitte geschlossenen Auftrags erforderlich und gemäß Art. 6 Abs. 1 lit. b) DSGVO gerechtfertigt.
    </p>
      
    <p>
      Bitte beachten Sie, dass die Allgemeinen Auftragsbedingungen von Deloitte in der Regel eine Mitwirkungspflicht des jeweiligen Kunden vorsehen, Deloitte alle für die Durchführung des Auftrags erforderlichen Unterlagen und Informationen zu übermitteln. Insofern ist die Bearbeitung des jeweiligen Auftrags und die damit verbundene Erfüllung der vertraglich vereinbarten Dienstleistung durch Deloitte nicht oder nur noch eingeschränkt möglich, wenn und soweit die notwendigen Informationen nicht bereitgestellt werden.
    </p>
      
    <p>
      Da Deloitte gesetzlich zu einer ordnungsgemäßen Aktenführung, umfangreichen Dokumentation seiner Mandate und Aufträge (auch über den Abschluss eines Auftrags hinaus) sowie zur Einhaltung weiterer Aufbewahrungs- und Dokumentationspflichten verpflichtet ist, verarbeitet Deloitte Ihre personenbezogenen Daten im Rahmen von zu dokumentierenden Unterlagen, Arbeitsergebnissen oder zugehöriger kundenbezogener Korrespondenz zudem zum Zwecke der Aktenführung, der Dokumentation sowie der Archivierung sowohl in Form von Papierakten als auch im Rahmen von hierzu eingesetzten IT-Systemen auf der Grundlage von Art. 6 Abs. 1 lit. c) DSGVO zur Erfüllung unserer vorgenannten gesetzlichen Pflichten.
    </p>
      
    <p>
      Ungeachtet der vorstehenden Zwecke verarbeitet Deloitte im Rahmen des gesetzlich Zulässigen Ihre Kontaktdaten (insbesondere Name, Anschrift, E-Mail-Adresse) darüber hinaus zu Marketing- und Webezwecken, d.h. bspw. um Ihnen Informationen zu unseren weiteren -Angeboten oder Veranstaltungen zukommen zu lassen. Dies erfolgt auf der Grundlage von Einwilligungen und/oder eines berechtigten wirtschaftlichen Interesses von Deloitte Sinne des Art. 6 Abs. 1 lit. f), ihre Kunden über weitere eigene Angebote und Veranstaltungen zu informieren und somit eine langfristige Kundenbeziehung aufbauen und aufrechterhalten zu können. 
    </p>
      
    <p>
      Schließlich verarbeitet Deloitte Ihre Kontaktdaten auch zur Pflege unserer Geschäftskontakte, wenn wir diese im Rahmen einer geschäftlichen Veranstaltung, im Rahmen eines geschäftlichen Termins (bspw. durch Austausch von Visitenkarten) oder im Rahmen eines Auftrags erhalten haben, und übertragen diese in das von uns genutzte CRM-System (Customer-Relationship-Management-System).
    </p>
      
    <p>
      Da Deloitte ein berechtigtes wirtschaftliches Interesse daran hat, im Rahmen des Geschäftsverkehrs entstandene Kontakte auch über den Erstkontakt hinaus zu pflegen, zum Aufbau einer Geschäftsbeziehung zu nutzen und hierfür mit den Betroffenen in Kontakt zu bleiben, erfolgt die vorgenannte Verarbeitung Ihrer personenbezogenen Daten auf der Grundlage von Art. 6 Abs. 1 lit. f) DSGVO.
    </p>
      
    <h3>Kategorien von Empfängern von Daten und Transfer in Drittländer</h3>
  
    <p>
      Im Zusammenhang mit der Durchführung unserer beauftragten Dienstleistung werden personenbezogene Daten, wie nachfolgend konkretisiert, ggf. auch an Dritte übermittelt. Insofern kann es zu einem Datentransfer sowohl in das europäische und außereuropäische Ausland sowie zur Speicherung Ihrer personenbezogenen Daten außerhalb der EU kommen:
    </p>
      
    <h4>An andere Deloitte-Mitgliedsunternehmen<sup><a id="index1" routerLink="" (click)="scroll('footnote1')">1</a></sup> zwecks Zusammenarbeit im Rahmen unserer Leistungserbringung</h4>
  
    <p>
      Soweit zur Erbringung der beauftragten Dienstleistung erforderlich, d.h. im Fall eines Auslandsbezugs oder bei erforderlicher Expertise eines ausländischen Kollegen, kooperiert Deloitte mit weiteren Gesellschaften aus dem globalen Deloitte-Netzwerk. Soweit ein solcher Transfer an eine Netzwerkgesellschaft außerhalb der EU/des Europäischen Wirtschaftsraums erfolgt, wird ein angemessenes Datenschutzniveau durch die Verwendung von Standardvertragsklauseln der EU-Kommission im Sinne des Art. 46 Abs. 2 lit. c) DSGVO gewährleistet. Die EU-Standardvertragsklauseln können Sie unter <a href="https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF">https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF</a> abrufen.
    </p>
      
    <h4>An Behörden, Gerichte oder andere Stellen</h4>
  
    <p>
      Im Zusammenhang mit der Durchführung unserer Leistungen kann es zudem erforderlich sein, Informationen, Arbeitsergebnisse und Unterlagen an Behörden, Gerichte oder andere öffentliche oder private Stellen (im Falle eines Auslandsbezugs auch im Ausland) zu übermitteln. Gleiches gilt für die Fälle, in denen Deloitte einer gesetzlichen, behördlichen oder gerichtlichen Anordnung zur Herausgabe/Offenlegung von personenbezogenen Daten verpflichtet ist. Dies erfolgt nur dann, wenn dem keine gesetzlichen Verschwiegenheitspflichten entgegenstehen.
    </p>
      
    <h4>An Deloitte-interne Dienstleister sowie externe IT-Dienstleister</h4>
  
    <p>
      Deloitte nutzt im Rahmen seiner Tätigkeit in Einzelfällen weitere deutsche oder ausländische Deloitte-Netzwerkgesellschaften als netzwerkinterne IT-Dienstleister, die Leistungen des Betriebs, der Wartung und Pflege der von den Deloitte-Netzwerkgesellschaften genutzten IT-Systeme und Applikationen erbringen. Ein Einsatz dieser Gesellschaften mit Zugriffsrechten auf personenbezogene Daten erfolgt nur, wenn dies in den Auftragsvereinbarungen mit unseren Kunden vereinbart wurde oder im Einzelfall ohne Zustimmung gesetzlich zulässig ist.
    </p>
    
    <p>
      Soweit ein Zugriff durch eine Netzwerkgesellschaft außerhalb des Europäischen Wirtschaftsraums erfolgt, wird ein angemessenes Datenschutzniveau durch die Verwendung von Standardvertragsklauseln der EU-Kommission im Sinne des Art. 46 Abs. 2 lit. c) DSGVO gewährleistet. 
    </p>
      
    <p>
      Fachspezifische und mandatsbezogen eingesetzte IT-Dienstleister, die z.B. bei der Mandatsbearbeitung fachspezifische Anwendungen sowie ggf. Cloud Services werden soweit gesetzlich erforderlich nur in Abstimmung mit unseren Kunden genutzt.
    </p>
      
    <h3>Ihre Rechte im Zusammenhang mit der Datenverarbeitung</h3>
  
    <p>
      Die DSGVO räumt Betroffenen im Wesentlichen die nachfolgenden Rechte ein, welche Sie jederzeit durch Kontaktaufnahme mit dem in diesen Informationen genannten Datenschutzbeauftragten unter <a href="mailto:datenschutz@deloitte.de">datenschutz@deloitte.de</a> bzw. <a href="mailto:kontakt@deloitte.de">kontakt@deloitte.de</a> geltend machen können.
    </p>
      
    <p>
      Sie können von Deloitte grundsätzlich jederzeit Auskunft darüber verlangen, ob und welche personenbezogenen Daten über Sie bei Deloitte wie verarbeitet werden bzw. gespeichert sind. Bitte beachten Sie, dass Ihr Recht auf Auskunft insoweit eingeschränkt sein kann, als eine solche Auskunft im Widerspruch zum Berufsgeheimnis stehen und insofern geheimhaltungsbedürftige Informationen offenbart würden.
    </p>
      
    <p>
      Neben Ihrem Recht auf Auskunft können Sie jederzeit die Berichtigung Ihrer Daten verlangen. Hinzu kommt ein Recht auf Löschung Ihrer Daten, wenn und soweit die Daten für die Zwecke, für die sie erhoben wurden, nicht mehr benötigt werden oder, wenn die Verarbeitung auf Ihrer Einwilligung beruht, Sie Ihre Einwilligung aber widerrufen haben. Das vorgenannte Recht auf Löschung Ihrer Daten entfällt, sofern Ihre Daten aufgrund einer gesetzlichen Pflicht nicht gelöscht werden dürfen oder aufgrund einer gesetzlichen Pflicht verarbeitet werden müssen oder eine Datenverarbeitung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist. 
    </p>
      
    <p>
      Darüber hinaus haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten von Deloitte zu verlangen. 
    </p>
      
    <p>
      Hinzu kommt ein Recht auf Datenübertragbarkeit, d.h. Sie können verlangen, von Deloitte die von Ihnen bereitgestellten Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und/oder dass diese Daten an einen anderen Verantwortlichen übermittelt werden. Bitte beachten Sie, dass dies nicht gilt, sofern Sie uns die Daten auf der Grundlage einer Einwilligung oder aufgrund eines mit Ihnen abgeschlossenen Vertrages zur Verfügung gestellt haben oder die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
    </p>
      
    <p>
      Sofern Deloitte Ihre personenbezogenen Daten auf der Grundlage von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet (z.B., wenn Ihr Arbeitgeber als Kunde von Deloitte uns Ihre personenbezogenen Daten als Ansprechpartner in Ihrem Unternehmen übermittelt hat, oder wenn wir Ihre Kontaktdaten nutzen, um Ihnen Informationen über Angebote und Veranstaltungen von Deloitte zuzusenden), können Sie jederzeit Widerspruch gegen diese Verarbeitung einlegen.
    </p>
      
    <h3>Beschwerderecht bei einer Datenschutzaufsichtsbehörde</h3>
  
    <p>
      Neben den vorstehend aufgezeigten Betroffenenrechte haben Sie zudem gemäß Art. 77 DSGVO das Recht auf Beschwerde bei einer Datenschutzaufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen das Datenschutzrecht verstößt. Zuständig ist jeweils die Aufsichtsbehörde des Bundeslandes, in dem die verantwortliche Stelle Ihren Sitz hat.
    </p>
      
    <h3>Dauer der Datenspeicherung</h3>
  
    <p>
      Bitte beachten Sie, dass Deloitte Ihre personenbezogenen Daten solange speichert und verarbeitet, wie es für die Erfüllung der vorstehend aufgezeigten Verarbeitungszwecke erforderlich ist. Soweit personenbezogene Daten Gegenstand von gesetzlichen Aufbewahrungspflichten oder Bestandteil von Unterlagen sind, die gesetzlichen Aufbewahrungspflichten unterliegen, wird Deloitte diese Daten für die Dauer der gesetzlich festgelegten Aufbewahrungsfrist speichern.
    </p>
      
    <p>
      Je nach Kategorie der Dokumente speichert Deloitte personenbezogene Daten aufgrund anwendbarer gesetzlicher Aufbewahrungspflichten
    </p>
  
    <p>
      Es ist die längste Aufbewahrungsfrist maßgeblich, soweit die betroffenen Daten verschiedenen Aufbewahrungsfristen unterliegen, und die gesetzlich vorgeschriebene Aufbewahrungsfrist kann sich je nach Einzelfall verlängern, wenn bspw. die Informationen zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen auch nach Ablauf der Aufbewahrungsfrist benötigt werden.
    </p>
  
    <p><small>
      <a href="" id="footnote1" (click)="scroll('index1')">1</a>) Deloitte bezieht sich auf Deloitte Touche Tohmatsu Limited („DTTL“), eine „private company limited by guarantee“ (Gesellschaft mit beschränkter Haftung nach britischem Recht), ihr Netzwerk von Mitgliedsunternehmen und ihre verbundenen Unternehmen. DTTL und jedes ihrer Mitgliedsunternehmen sind rechtlich selbstständig und unabhängig. DTTL (auch „Deloitte Global“ genannt) erbringt selbst keine Leistungen gegenüber Kunden. Eine detailliertere Beschreibung von DTTL und ihren Mitgliedsunternehmen finden Sie auf <a href="www.deloitte.com/de/UeberUns">www.deloitte.com/de/UeberUns</a>.
    </small></p>
    -->
