import { Component } from '@angular/core';

@Component({
  selector: 'aihub-privacy',
  templateUrl: './privacy.component.html',
  host: {
    class: 'd-flex py-4'
  }
})
export class PrivacyComponent {

  public scroll = (id: string) => {
    const el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth', block: 'end' });
    return false;
  }

}
