import { InjectionToken, inject } from '@angular/core';
import { autoLoginFactory } from '../utils/auto-login.factory';
import { ActivatedRoute, Params } from '@angular/router';

export const AuthRedirectUri = new InjectionToken<(route: ActivatedRoute) => ({path: string; query?: Params})>('path to navigate if logged in');
export const AutoLoginToken  = new InjectionToken<boolean>('Auto Login', {
    providedIn: 'root',
    factory: () => autoLoginFactory(),
});

export declare type AfterLoginHook = (route: ActivatedRoute) => {path: string, queryParams?: Params};
