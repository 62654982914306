import 'firebase/auth';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule, FIREBASE_OPTIONS, FirebaseOptions } from '@angular/fire';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService, JwtInterceptor, AuthGuard } from './utils';
import { AuthProviderToken, JWT_INTERCEPT_HOOK } from './data';

function preInterceptorHookFactory(hook?: (uri: string) => boolean): (uri: string) => boolean {
    return hook ? (uri: string) => hook(uri) : () => true;
}

export interface AiHubAuthConfiguration {
    authProvider: string;
    firebase: FirebaseOptions;
    jwtInterceptHook?: (uri: string) => boolean;
}
@NgModule({
    imports: [
        CommonModule,
        AngularFireAuthModule,
        AngularFireModule,
    ],
    providers: [
        AuthService,
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ]
})
export class AiHubAuthModule {

    public static forRoot(
        config: AiHubAuthConfiguration
    ): ModuleWithProviders<AiHubAuthModule> {
        return {
            ngModule: AiHubAuthModule,
            providers: [
                { provide: AuthProviderToken, useValue: config.authProvider },
                { provide: FIREBASE_OPTIONS,  useValue: config.firebase },
                { provide: JWT_INTERCEPT_HOOK, useFactory: () => preInterceptorHookFactory(config.jwtInterceptHook) }
            ]
        };
    }
}
