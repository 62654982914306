import { Component } from '@angular/core';

@Component({
  selector: 'aihub-init-spinner',
  templateUrl: './init-spinner.component.html',
  host: {
    class: 'container h-100 d-flex'
  }
})
export class InitSpinnerComponent { }
