import { Component } from '@angular/core';

@Component({
  selector: 'aihub-imprint',
  templateUrl: './imprint.component.html',
  host: {
    class: 'd-flex py-4'
  }
})
export class ImprintComponent {}
