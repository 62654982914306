import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Loader } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public loader$ = new BehaviorSubject<Loader>({ show: false });
  public isInitComplete$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  public show() {
    this.loader$.next({ show: true });
  }

  public hide() {
    this.loader$.next({ show: false });
  }

  public completeInit() {
    this.isInitComplete$.next(true);
  }
}
