import { Component } from '@angular/core';
import { DownloadHelper } from './utils/download';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './main.html',
  styleUrls: ['./main.scss']
})
export class DownloadMainComponent {

    public constructor(
        private download: DownloadHelper,
        private route: ActivatedRoute
    ) {}

    public downloadService() {
        this.route.queryParams.pipe(
            switchMap((params) => this.download.fetchDownloadUrl(params)),
        ).subscribe((url) => {
            // const objectUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.click();
        });
    }
}
