import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { JWT_INTERCEPT_HOOK } from '../../data';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    public constructor(
        @Inject(JWT_INTERCEPT_HOOK) private interceptValidator: (uri: string) => boolean,
        private authService: AuthService
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /**
         * @inject only on specific url
         */
        if (this.interceptValidator(request.url)) {
            return this.authService.getJwtToken().pipe(
                switchMap((token: string) => {
                    if (token) {
                        request = request.clone({ setHeaders: { Authorization: 'Bearer ' + `${token}` } });
                    }
                    return next.handle(request);
                }));
        }

        return next.handle(request);
    }
}
