import { Component } from '@angular/core';

@Component({
  selector: 'aihub-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  host: {
    class: 'd-flex py-4'
  }
})
export class TermsOfUseComponent {}
