import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { langList } from '../models';

@Injectable({
  providedIn: 'root'
})
export class StringHandlerService {

  private langList = langList;

  public device = 'large';

  constructor(public breakpointObserver: BreakpointObserver) {
    this.setBreakPoints();
  }

  getLangList() {
    return this.langList;
  }

  setBreakPoints() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        this.device = 'small';
      }
      if (result.breakpoints[Breakpoints.Small]) {
        this.device = 'small';
      }
      if (result.breakpoints[Breakpoints.Medium]) {
        this.device = 'large';
      }
      if (result.breakpoints[Breakpoints.Large]) {
        this.device = 'large';
      }
      if (result.breakpoints[Breakpoints.XLarge]) {
        this.device = 'large';
      }
    });
  }

  getDevice() {
    return this.device;
  }

}
