<div class="container h-100">
  <h1 class="mt-4">Imprint</h1>
  <p class="mt-4">Inhaltlich Verantwortlicher gemäß §6 MDStV und im Sinne des Presserechts: Steffen Legler</p>

  <p class="mt-4">Deloitte Consulting GmbH </p>
  <p>
    Schwannstraße 6<br/>
    40476 Düsseldorf<br/>
    Telefon: +(49) 211 8772 – 01<br/>
    Fax: +(49) 211 8772 – 82277<br/>
    E-Mail: <a href="mailto:kontakt@deloitte.de"></a><br/>
    Sitz der Gesellschaft: München
  </p>

  <p>
    Sitz der Gesellschaft:<br/>
    Düsseldorf<br/>
  </p>

  <p>
    Geschäftsführer:<br/>
    Nathan Houser & Edgar Klein
  </p>

  <p>
    Amtsgericht Düsseldorf:<br/>
    HRB 67410 Klein
  </p>

  <p>
    Vorsitzender des Aufsichtsrats:<br/>
    Jörg Tesch
  </p>

  <p>
    Die Berufshaftpflichtversicherung besteht bei der Versicherergemeinschaft für das wirtschaftliche Prüfungs- und Treuhandwesen Dotzheimer Str. 23, 65185 Wiesbaden. Der Versicherungsschutz umfasst eine weltweite Deckung für ausländisches Recht und ausländische Gerichtsstände.
  </p>

  <p>
    Umsatzsteueridentifikationsnummer:<br/>
    DE 180108746
  </p>
  <br/>
  <br/>
  <br/>
  <div style="color:grey"> 
    <h6> <b>
      Niederlassungen
    </b></h6>
    <p>
      Deloitte Consulting GmbH
    </p>

    <p>
      Kurfürstendamm 23 <br/>
      10719 Berlin <br/>
      T: +49 (0)30 25468 01
    </p>
    
    <p>
      Schwannstraße 6 <br/>
      40476 Düsseldorf <br/>
      T: +49 (0)211 8772 01
    </p>

    <p>
      Franklinstraße 46 – 48 <br/>
      60486 Frankfurt/Main <br/>
      T: +49 (0)69 97137 0
    </p>

    <p>
      Axel-Springer-Platz 3 <br/>
      20355 Hamburg <br/>
      T: +49 (0)40 32080 0
    </p>
    
    <p>
      Theaterstraße 15 <br/>
      30159 Hannover <br/>
      T: +49 (0)511 93636 0
    </p>

    <p>
      Rosenheimer Platz 4 <br/>
      81669 München <br/>
      T: +49 (0)89 29036 0
    </p>

    <p>
      Löffelstraße 42 <br/>
      70957 Stuttgart <br/>
      T: +49 (0)711 16554 01
    </p>
  </div>
</div>