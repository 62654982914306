import { Injectable, Inject, RendererFactory2, Renderer2 } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable, fromEvent, of, zip, } from 'rxjs';

import { AuthProviderToken } from '../../data';
import { map, take, switchMap, finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {

    private oAuthProvider: firebase.auth.OAuthProvider;

    private logoutHooks: Observable<boolean>[] = [];

    private renderer: Renderer2;

    public constructor(
        @Inject(AuthProviderToken) private authProvider: string,
        private angularFireAuth: AngularFireAuth,
        rendererFactory: RendererFactory2
    ) {
        this.oAuthProvider = new firebase.auth.OAuthProvider(this.authProvider);
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public addLogoutHook(hook: Observable<boolean>): void {
        this.logoutHooks.push(hook);
    }

    /**
     * checks if user is allready authorized
     */
    public isAuthorized(): Observable<boolean> {
        return this.angularFireAuth.user.pipe(
            map((user) => !!user),
            take(1)
        );
    }

    /**
     * get token or current user for request
     */
    public getJwtToken(): Observable<string> {
        return this.angularFireAuth.idToken;
    }

    public getUser(): Observable<firebase.User> {
        return this.angularFireAuth.user;
    }

    /**
     * perform login action
     */
    public login(): void {
        this.angularFireAuth.onAuthStateChanged((user) => {
            if (!user) {
                this.oAuthProvider.addScope('User.Read');
                this.angularFireAuth.signInWithRedirect(this.oAuthProvider);
            }
        });
    }

    /**
     * perform logout action
     */
    public logout(): Observable<void> {
        return this.logoutFromMicrosoft()
            .pipe(
                switchMap(() => this.angularFireAuth.signOut())
            );
    }

    /**
     * logout from microsoft account, since the redirect is for microsoft seems to be only work
     * if we added the logout route in azure, simply call the url to logout for microsoft in an iframe
     * and wait for first load event that means the iframe has loaded at least one time
     */
    private logoutFromMicrosoft(): Observable<boolean> {

        const iframe = document.createElement('iframe');
        this.renderer.setStyle(iframe, 'display', 'none');
        this.renderer.setAttribute(iframe, 'src', `https://login.microsoftonline.com/common/oauth2/logout`);

        /**
         * Not sure we could miss one load event if we attach this first, and then listen to load event,
         * so bundle attach to body and listen to load event, so only if both triggers we get notfied.
         *
         * Since there could be one load event if we are allready logged out from microsoft account
         * or 3 if we logging out from microsoft
         *
         * At the end take only one and complete this stream.
         */
        return zip(
            of(this.renderer.appendChild(document.body, iframe)),
            fromEvent(iframe, 'load')
        )
        .pipe(
            map(() => true),
            finalize(() => this.renderer.removeChild(document.body, iframe)),
            take(1)
        );
    }
}
