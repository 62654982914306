import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
    MatSnackBarRef
} from '@angular/material/snack-bar';
// import { SnackbarComponent } from '../components/common/snackbar/snackbar.component'

@Injectable({providedIn: 'root'})
export class AlertService {
    // Snackbar configuration
    snackBarConfig: MatSnackBarConfig;
    snackBarRef: MatSnackBarRef<any>;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    snackBarAutoHide = '10000';

    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(router: Router, private snackBar: MatSnackBar,
        // public snackBarRef: MatSnackBarRef<SnackbarComponent>,
        // @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
        this.openSnackBar(message, 'success');
    }

    error(message, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
        this.openSnackBar(message, 'error');
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    openSnackBar(message, panelClass) {
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.horizontalPosition = this.horizontalPosition;
        this.snackBarConfig.verticalPosition = this.verticalPosition;
        this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
        this.snackBarConfig.panelClass = panelClass;
        this.snackBarRef = this.snackBar.open(message, '', this.snackBarConfig);
        // this.snackBar.openFromComponent(SnackbarComponent, {
        //     data: message,
        //     ...this.snackBarConfig
        // });
    }
}
