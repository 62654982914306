import { InjectionToken } from '@angular/core';

export const AuthProviderToken  = new InjectionToken('angular fire auth provider');

/**
 * declare injection token which will consumed by jwt-interceptor
 * since this should be a bit more flexible we could check into this one
 * the uri which has been passed should added jwt token otherwise
 * event will pass
 */
export const JWT_INTERCEPT_HOOK = new InjectionToken('authorization pre intercept hook for token');
