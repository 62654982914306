<div class="container h-100">
  <h1 class="mt-4">Terms of Use</h1>
  <p class="mt-3">
    <b>Deloitte.ai</b> consists of various individual worldwide, country or service-specific websites. Each of these individual websites that you view at any time during your use of deloitte.ai is assigned according to the "location" in the upper right corner of the website.</p>
  
  <p>
    These Terms of Use apply to each worldwide, regional, country or service-specific Website that you have viewed on deloitte.ai prior to clicking on these Terms of Use. Such individual Website is referred to in the Terms of Use as "this Website". </p>
  
  <p><b>
    By using this Website, you agree to be bound by these Terms of Use. If you do not agree to these Terms of Use, you are not permitted to use this Website and you must immediately cease using this Website. </b></p>
  
  <p>
    The  <b>Deloitte Network </b> refers to Deloitte Touche Tohmatsu Limited ("DTTL"), the member firms of DTTL and its affiliates. Each separate worldwide, regional, country or service-specific website within deloitte.ai (as identified in the upper right corner of the Website) is provided by a separate company within the Deloitte Network. 
   </p>
  
  <p>
    Deloitte & Touche GmbH Wirtschaftsprüfungsgesellschaft, as a DTTL member firm in Germany, is the company within the Deloitte Network that provides this Website and is referred to in these Terms of Use as "we", "us" or "our". Even if certain sections of these Terms of Use refer to other companies within the Deloitte Network, these Terms of Use apply solely between you and us and not with any such other company. 
   </p>
  
  <h2 class="mt-4">Use of the Content; Restrictions </h2>
  <p>
    Except as otherwise provided in the applicable Content and provided that you comply with all obligations under the Terms of Use, you may view, copy, print and distribute (but not modify) the Content on this Web Site; provided that (i) the use is for non-commercial informational purposes only, and (ii) any copy you make of the Content contains the copyright notice or other attribution of the Content. The contents of the Site may be used for informational purposes only. In particular, the content of the Website is not to be used in combination with any other work, publication or website, whether by you or by any third party. 
   </p>
  
  <p>
    You may not copy or use the software, proprietary processes or technologies underlying or described on this website. Every program, publication, design and plan, sample, model and product as well as every process, software, technology and information, know-how and idea described on this website are generally subject to the scope of application of further rights (in particular rights to so-called intellectual property). As a rule, these rights are held by Deloitte & Touche GmbH Wirtschaftsprüfungsgesellschaft as a nationally independent and autonomous company and, if applicable, by Deloitte Touche Tohmatsu Limited, a "private company limited by guarantee" (a limited liability company under British law), and/or its other nationally independent member firms, (together "Deloitte"), but may also be owned by third parties who have granted Deloitte & Touche GmbH Wirtschaftsprüfungsgesellschaft a right to display on the Deloitte Website or a corresponding right to link (electronic "link") to their websites. The operation of the Deloitte Website on the Internet does not constitute a transfer of such rights or the granting of any right of use (beyond the scope set forth herein). 
  </p>
  
  <p>When accessing and using this Website you shall comply with all applicable laws. 

  </p>
  
  <h2 class="mt-4">Intellectual property rights; No use of the Deloitte name or logo</h2>

  <p>Unless otherwise noted, the content of this Website was provided by us or another member of the Deloitte network. </p>
  <p>
    This Website and its contents are protected by copyright, trademark and other laws of the United States and/or other countries. We and our licensors reserve all rights not expressly granted in the Terms of Use. 
   </p>
  
  <p>
    "Deloitte", "Touche", "Tohmatsu", "Deloitte Touche Tohmatsu", "Deloitte & Touche", the Deloitte logo and local language variants of the trademarks mentioned, and certain product names and all headers and footers referring to them as well as symbols that appear on this Website are trademarks (trademarks, registered trademarks, service marks, quality marks, etc.) of companies in the Deloitte Network. Except as expressly permitted in the Terms of Use, the name "Deloitte", "Touche", "Tohmatsu", "Deloitte Touche Tohmatsu", "Deloitte & Touche", the Deloitte logo or local language variants of the trademarks mentioned may not be used alone or in combination with other words or design elements. The names, signs or logos mentioned may not be used in any written, oral, visual or other form in press releases, advertisements or other advertising or marketing materials or media, unless expressly authorized in writing by Deloitte Touche Tohmatsu or its representatives. To obtain such permission, please contact us.

  
  <p>
    References on this website to third party trademarks are for identification purposes only and do not imply that these third parties have approved this website or its contents. These Terms of Use do not authorize you to use the trademarks of these third parties.
   </p>
  
   <h2 class="mt-4">exclusion and limitation of liability</h2>

   <p>
     The Deloitte website was set up to provide general information and advice on specific topics, but not to deal with individual topics in greater depth. The Deloitte Website is not designed to provide binding advice (including on accounting, tax, legal or investment matters), any other service or work, or to answer any related question. Accordingly, you may not rely on the content of this Website for any decision or action and should always consult a suitably qualified advisor with respect to your personal financial and business matters. However, if, despite such advice, you decide to make a decision or take action based on the content of this Website, you do so entirely at your own risk and shall not be liable to Deloitte, its shareholders, directors or employees, for any damages or consequential damages arising out of your decision or action based on the content of this Website. This applies regardless of the legal basis (national or international, contract law, tort law, etc.).
   </p>
   
   <p>
     The information presented on the website (including available documents) is provided in the above-mentioned framework only in its original form and in its original context. Deloitte & Touche GmbH Wirtschaftsprüfungsgesellschaft does not warrant, represent or otherwise guarantee the accuracy, precision, completeness, quality and/or usefulness of the information (including the available documents) presented on the Website.
   </p>
   
   <p>
     YOUR USE OF THIS WEBSITE IS AT YOUR OWN RISK AND YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS ARISING OUT OF SUCH USE, INCLUDING WITHOUT LIMITATION LOSS OF SERVICEABILITY OR DATA. IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES OF ANY KIND, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR OTHERWISE, IN CONNECTION WITH OR ARISING OUT OF THE USE OF THIS WEB SITE, EVEN IF WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
   </p>
   
   <p>
     CERTAIN ELECTRONIC LINKS ON THIS WEB SITE LEAD TO WEB SITES, RESOURCES OR TOOLS PROVIDED BY THIRD PARTIES OVER WHOM WE HAVE NO CONTROL, INCLUDING, WITHOUT LIMITATION, THOSE PROVIDED BY OTHER MEMBERS OF THE DELOITTE NETWORK OR INDIVIDUAL EMPLOYEES OF SUCH COMPANIES. WITHOUT LIMITING THE FOREGOING, WE DO NOT REPRESENT OR WARRANT, EXPRESSLY OR IMPLICITLY, SUCH SITES, RESOURCES AND UTILITIES, AND LINKS TO SUCH SITES, RESOURCES AND UTILITIES SHOULD NOT BE CONSTRUED AS AN ENDORSEMENT BY US OF THEM OR THEIR CONTENTS.
   </p>
   
   <p>
     the foregoing exclusions and limitations of liability are not only applicable to us, but also to and for the benefit of any other member of the deloitte network and to our and their employees.
   </p>
</div>
<!--
  <div class="container h-100">
  <h1 class="mt-4">Terms of Use</h1>
  <p class="mt-3">
    <b>Deloitte.ai</b> besteht aus verschiedenen individuellen weltweiten, länder- oder dienstleistungsspezifischen Webseiten. Jede dieser eigenen Webseiten, die Sie zu irgendeinem Zeitpunkt während Ihrer Benutzung von <b>deloitte.ai</b> ansehen, ist gemäß dem "Standort" in der oberen rechten Ecke der Webseite entsprechend zugeordnet.
  </p>
  
  <p>
    Diese Nutzungsbedingungen gelten für die einzelnen weltweiten, regionalen, länder- oder dienstleistungsspezifischen Webseiten, die Sie auf <b>deloitte.ai</b> angesehen haben bevor Sie diese Nutzungsbedingen angeklickt haben. Diese individuelle Webseite wird in den Nutzungsbedingungen als "diese Webseite" bezeichnet.
  </p>
  
  <p><b>
    Durch die Benutzung dieser Webseite willigen Sie in die Nutzungsbedingungen ein. Falls Sie diesen Nutzungsbedingungen nicht zustimmen, ist es Ihnen nicht erlaubt, diese Webseite zu nutzen und Sie müssen deren Benutzung sofort beenden.
  </b></p>
  
  <p>
    Das <b>"Deloitte Netzwerk"</b> bezieht sich auf Deloitte Touche Tohmatsu Limited (<b>"DTTL"</b>), die Mitgliedsfirmen von DTTL und deren verbundenen Unternehmen. Jede eigene weltweite, regionale, länder- oder dienstleistungsspezifische Webseite innerhalb von <b>deloitte.ai</b> (gemäß der Zuordnung in der oberen, rechten Ecke der Webseite) wird von einem eigenen Unternehmen des Deloitte Netzwerks zur Verfügung gestellt.
  </p>
  
  <p>
    Die Deloitte & Touche GmbH Wirtschaftsprüfungsgesellschaft, als DTTL Mitgliedsfirma in Deutschland, ist das Unternehmen innerhalb des Deloitte Netzwerks, das diese Webseite zur Verfügung stellt und wird in diesen Nutzungsbedingungen mit "wir", "uns" oder "unserer/m/n" betitelt. Selbst falls sich bestimmte Abschnitte dieser Nutzungsbedingungen auf andere Unternehmen im Deloitte Netzwerk beziehen, gelten diese Nutzungsbedingungen ausschließlich im Verhältnis zwischen Ihnen und uns und nicht mit einem dieser anderen Unternehmen.
  </p>
  
  <h2 class="mt-4">Verwendung der Inhalte; Einschränkungen</h2>
  <p>
    Sofern im jeweiligen Inhalt nicht anders bestimmt und unter der Voraussetzung, dass Sie alle Verpflichtungen der Nutzungsbedingungen einhalten, ist es Ihnen erlaubt, die Inhalte dieser Webseite anzusehen, zu kopieren, zu drucken und zu verteilen (aber nicht zu verändern); vorausgesetzt, dass (i) die Verwendung ausschliesslich für nicht-kommerzielle Informationszwecke erfolgt, und (ii) jede von Ihnen vorgenommene Kopie der Inhalte den Copyright-Hinweis oder sonstige Zuordnung der Inhalte enthält. Die Inhalte der Webseite dürfen allein zu Informationszwecken verwendet werden. Insbesondere werden die Inhalte der Webseite nicht dazu verwendet, mit anderen Arbeiten, Publikationen oder Webseiten kombiniert zu werden, unabhängig davon, ob es sich hierbei um solche von Ihnen oder Dritten handelt.
  </p>
  
  <p>
    Es ist Ihnen nicht gestattet, die Software, proprietäre Prozesse oder Technologien, die dieser Webseite zugrundeliegen oder dort beschrieben werden, zu kopieren oder zu nutzen. Jedes auf dieser Webseite beschriebene Programm, jede Veröffentlichung, jeder Entwurf und Plan, jedes Muster, Modell und Produkt sowie jedes Verfahren, jede Software, Technologie und Information, jedes Know-how und jede Idee unterliegen grundsätzlich dem Anwendungsbereich weiterer Rechte (insbesondere Rechten am so genannten geistigen Eigentum). Diese Rechte stehen in der Regel der Deloitte & Touche GmbH Wirtschaftsprüfungsgesellschaft als national eigenständigem und unabhängigem Unternehmen zu, sowie gegebenenfalls Deloitte Touche Tohmatsu Limited, eine „private company limited by guarantee“ (Gesellschaft mit beschränkter Haftung nach britischem Recht), und/oder deren anderen national eigenständigen und davon unabhängigen Mitgliedsunternehmen, (zusammen "Deloitte"), können aber auch Dritten zustehen, die der Deloitte & Touche GmbH Wirtschaftsprüfungsgesellschaft ein Recht zur Darstellung auf der Deloitte Webseite oder ein entsprechendes Recht zur Verlinkung (elektronischen "Verweisung") auf deren Webseiten eingeräumt haben. Mit dem Betrieb der Deloitte Webseite im Internet ist weder eine Übertragung derartiger Rechte noch die Einräumung irgendeines Nutzungsrechts (über den hier festgelegten Rahmen hinaus) verbunden.
  </p>
  
  <p>Beim Zugriff auf diese Webseite und ihrer Nutzung befolgen Sie alle anwendbaren Gesetze.</p>
  
  <h2 class="mt-4">Geistige Eigentumsrechte; Keine Verwendung des Namens oder des Logos von Deloitte</h2>

  <p>Sofern nicht anderweitig vermerkt, wurden die Inhalte dieser Webseite von uns oder einem anderen Mitgliedsunternehmen des Deloitte Netzwerks zur Verfügung gestellt.</p>
  
  <p>
    Diese Webseite und ihre Inhalte sind durch Copyright-, Trademark- und andere Gesetze der Vereinigten Staaten und/oder anderen Ländern geschützt. Wir und unsere Lizenzgeber behalten uns alle Rechte vor, die nicht ausdrücklich in den Nutzungsbedingungen gewährt werden.
  </p>
  
  <p>
    "Deloitte", "Touche", "Tohmatsu", "Deloitte Touche Tohmatsu", "Deloitte & Touche", das Deloitte Logo und lokale Sprachvarianten der genannten Warenzeichen, und bestimmte Produktnamen sowie alle darauf Bezug nehmenden Kopf- und Fußzeilen als auch Symbole, die auf dieser Webseite aufgeführt sind, sind Schutzmarken (Warenzeichen, eingetragene Warenzeichen, Dienstleistungszeichen, Gütezeichen etc.) von Unternehmen des Deloitte Netzwerks. Sofern nicht ausdrücklich in den Nutzungsbedingungen erlaubt, darf der Name "Deloitte", "Touche", "Tohmatsu", "Deloitte Touche Tohmatsu", "Deloitte & Touche", das Deloitte Logo oder lokale Sprachvarianten der genannten Warenzeichen weder allein noch in Kombination mit anderen Worten oder Designelementen verwendet werden. Die genannten Namen, Zeichen oder Logos dürfen weder in schriftlicher, noch mündlicher, visueller oder einer anderen Form in Medienmitteilungen, Werbeanzeigen oder anderen Werbe- oder Marketingmaterialien oder -medien verwendet werden, ausser dies wurde ausdrücklich schriftlich von Deloitte Touche Tohmatsu oder dessen Vertreter genehmigt. Um eine solche Genehmigung einzuholen, wenden Sie sich bitte an uns.
  </p>
  
  <p>
    Verweise dieser Webseite auf die Warenzeichen Dritter dienen ausschliesslich der Identifikation und bedeuten nicht, dass diese Drittparteien diese Webseite oder ihre Inhalte genehmigt haben. Diese Nutzungsbedingungen berechtigen Sie nicht zur Nutzung der Warenzeichen dieser Drittparteien.
  </p>
  
  <h2 class="mt-4">Haftungsausschluss und Haftungsbeschränkung</h2>

  <p>
    Die Deloitte Webseite wurde eingerichtet, um allgemeine Informationen und Hinweise zu bestimmten Themen wiederzugeben, jedoch nicht, um einzelne Themen vertieft zu behandeln. Die Deloitte Webseite ist nicht dafür konzipiert, einen verbindlichen Rat (auch nicht zu den Themen Bilanzierung, Steuern, Recht, Investitionen), eine sonstige Dienst- oder Werkleistung oder etwa eine Antwort auf eine damit im Zusammenhang stehende Frage zu geben. Dementsprechend können Sie sich für eine Entscheidung oder Maßnahme nicht auf Inhalte dieser Webseite stützen und sollten sich deshalb bei Fragen Ihrer persönlichen Finanzen und Geschäfte stets an einen entsprechend fachlich qualifizierten Berater wenden. Sollten Sie sich jedoch trotz dieses Rates bei einer Entscheidung oder Maßnahme auf Inhalte dieser Webseite stützen, handeln Sie ausschließlich auf eigenes Risiko; Schäden oder Folgeschäden, die dadurch entstehen, dass Sie eine Ihrer Entscheidungen oder Maßnahmen auf Inhalte dieser Webseite stützen, können gegenüber Deloitte nicht geltend gemacht werden, auch nicht gegenüber deren Gesellschaftern, Geschäftsführern oder Mitarbeitern von Deloitte. Dies gilt unabhängig von der Rechtsgrundlage (national oder international, Vertragsrecht, Recht der unerlaubten Handlungen etc.).
  </p>
  
  <p>
    Die auf der Webseite dargestellten Informationen (einschließlich der verfügbaren Dokumente) werden im oben genannten Rahmen nur in ihrer ursprünglichen Form und in ihrem ursprünglichen Zusammenhang zur Verfügung gestellt. Deloitte & Touche GmbH Wirtschaftsprüfungsgesellschaft übernimmt keinerlei Garantie, Zusicherung oder sonstige Gewährleistung für die Richtigkeit, Genauigkeit, Vollständigkeit, Qualität und/oder Verwendbarkeit der dort dargestellten Informationen (einschließlich der dort verfügbaren Dokumente).
  </p>
  
  <p>
    DIE BENUTZUNG DIESER WEBSEITE IST AUF EIGENE GEFAHR UND SIE ÜBERNEHMEN DIE VOLLE VERANTWORTUNG UND DAS VERLUSTRISIKO, DAS VON DER BENUTZUNG AUSGEHT, INKLUSIVE OHNE EINSCHRÄNKUNG DER VERLUST DER BETRIEBSFÄHIGKEIT ODER DER DATEN. WIR SIND NICHT VERANTWORTLICH FÜR ALLFÄLLIGE DIREKTE, INDIREKTE, SONDER-, NEBEN- ODER FOLGESCHÄDEN ODER STRAFZUSCHLÄGE ZUM SCHADENSERSATZ ODER SONSTIGE SCHÄDEN JEGLICHER ART, WEDER DURCH VERTRAG, GESETZ, UNERLAUBTE HANDLUNG (EINSCHLIESSICH, OHNE EINSCHRÄNKUNG, FAHRLÄSSIGKEIT) NOCH IN SONSTIGER FORM, IM ZUSAMMENHANG MIT ODER AUFGRUND DER NUTZUNG DIESER WEBSEITE, SELBST FALLS WIR VON DER MÖGLICHKEIT DERARTIGER SCHÄDEN WUSSTEN ODER WISSEN HÄTTEN MÜSSEN.
  </p>
  
  <p>
    BESTIMMTE ELEKTRONISCHE QUERVERWEISE (“LINKS”) AUF DIESER WEBSEITE FÜHREN ZU WEBSEITEN, RESSOURCEN ODER HILFSMITTELN, DIE VON DRITTPARTEIEN BEREIT GESTELLT WERDEN ÜBER DIE WIR KEINE KONTROLLE HABEN, INKLUSIVE, OHNE EINSCHRÄNKUNG, SOLCHER SEITEN, DIE VON ANDEREN MITGLIEDERN DES DELOITTE NETZWERKS ODER EINZELNEN MITARBEITERN DIESER FIRMEN BEREIT GESTELLT WERDEN. OHNE DAS VORANGEGANGENE EINZUSCHRÄNKEN, GEWÄHRLEISTEN ODER GARANTIEREN WIR WEDER AUSDRÜCKLICH NOCH IMPLIZIT DERARTIGE WEBSEITEN, RESSOURCEN UND HILFSMITTEL, UND LINKS AUF DIESE WEBSEITEN, RESSOURCEN UND HILFSMITTEL SOLLTEN NICHT ALS EMPFEHLUNG UNSERERSEITS DAFÜR ODER FÜR DEREN INHALTE INTERPRETIERT WERDEN.
  </p>
  
  <p>
    DIE OBENSTEHENDEN HAFTUNGSAUSSCHLÜSSE UND –BESCHRÄNKUNGEN GELTEN NICHT NUR FÜR UNS, SONDERN AUCH FÜR UND ZUGUNSTEN JEDES ANDEREn MITGLIEDES DES DELOITTE NETZWERKS UND FÜR UNSERE UND DEREN MITARBEITER.
  </p>
  
  <p>
    DIE OBENSTEHENDEN HAFTUNGSAUSSCHLÜSSE UND -GESCHRÄNKUNGEN GELTEN IM VOLLEN GESETZLICH ZUGELASSENEN UMFANG, FÜR VERTRÄGE, GESETZE, UNERLAUBTE HANDLUNGEN (EINSCHLIESSLICH, OHNE EINSCHRÄNKUNG, FAHRLÄSSIGKEIT) ODER IN SONSTIGER WEISE.
  </p>
  
  <h2 class="mt-4">Zusätzliche Bedingungen</h2>

  <p>
    Sollte eine dieser Nutzungsbedingungen an einem Gerichtsstand nicht gültig oder nicht durchsetzbar sein, dann (i) soll diese an diesem Gerichtsstand in juristisch grösstmöglichem Umfang dergestalt ausgelegt werden, dass ihr Zweck möglichst sinngemäss erfüllt wird und die sonstigen Nutzungsbedingungen sollen vollumfänglich wirksam bleiben, und (ii) an allen anderen Gerichtsständen sollen diese Nutzungsbedingungen vollumfänglich wirksam bleiben.
  </p>
  
  <p>
    Es steht uns frei, diese Nutzungsbedingungen jederzeit nach unserem eigenen Ermessen zu ändern, indem wir die geänderten Nutzungsbedingungen unter dem Link "Nutzungsbedingungen" (d.h. auf der Webseite, die Sie gerade ansehen) oder an sonstiger Stelle auf dieser Webseite bekannt machen . Solche Änderungen sind ab der Bekanntmachung gültig, es sei denn wir weisen explizit darauf hin. Es liegt in Ihrer Verantwortlichkeit, sich mit allfälligen Änderungen der Nutzungsbedingungen vertraut zu machen, indem Sie diese Webseite konsultieren. Indem Sie die Webseite nach einer Änderung der Nutzungsbedingungen weiter verwenden, stimmen Sie den geänderten Nutzungsbedingungen zu.
  </p>
  
  <p>
    „Die verwendeten Sammelbezeichnungen Bewerber, Absolvent, Praktikant, Kunde, Mitarbeiter o.ä. gelten für Männer und Frauen gleichermaßen und sind deshalb als geschlechtsneutral anzusehen“.
  </p>
</div>
-->