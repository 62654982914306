export function autoLoginFactory(): boolean {

    let isAutoLogin = false;

    /** get value from session storage */
    if (sessionStorage.getItem('autoLogin')) {
        isAutoLogin = sessionStorage.getItem('autoLogin') !== 'false';
    }

    return isAutoLogin;
}
