import { ActivatedRoute, Params } from '@angular/router';

export function afterLoginHook(route: ActivatedRoute) {
    const snapshot   = route.snapshot;
    const queryParam = snapshot.queryParams;

    const query  = queryParam.returnUrl.match(/^[^\?]+\?(.*?)$/)[1] as string;
    const params = query.split('&').reduce<Params>((param, qp) => {
        const [key, value] = qp.split('=');
        param[key] = value;
        return param;
    }, {});
    return {path: '/', queryParams: params };
}
