<button class="btn p-2 px-2 d-inline-flex" [ngClass]="getButtonClasses()" (click)="onClicked()" [disabled]="disabled || inProgress" style="height:40px;">
  <div>{{text}}</div>
  <div *ngIf="!inProgress" class="button-icon-container my-auto">
    <mat-icon class="ml-auto mr-0 my-auto">{{icon}}</mat-icon>
  </div>
  <div *ngIf="inProgress" class="button-icon-container my-auto">
    <div class="ml-auto my-auto">
      <mat-spinner class="spinner-white" diameter="18" [strokeWidth]="2"></mat-spinner>
    </div>
  </div>
</button>