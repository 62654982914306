import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';

import { LanguageList } from '../../models';
import { StringHandlerService } from '../../services/string-handler.service';
import { LoaderService } from '../../services/loader.service';
import { MatMenu } from '@angular/material/menu';
import { AuthService } from 'src/app/libs/auth';

export interface MenuItem {
    label: string;
    route: string;
    isExternal?: boolean;
}

export interface HeaderMenuItems {
    center: MenuItem[];
    user?: MenuItem[];
}

@Component({
    selector: 'aihub-header',
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('userMenu') usermenu: MatMenu;
    @ViewChild('langMenu') langMenu: MatMenu;
    @ViewChild('hamburgerMenu') hamburgerMenu: MatMenu;
    @Input() public appName = 'Cognitive Services Platform';
    @Input() public signUpEnabled = false;

    @Input()
    public menuItems: HeaderMenuItems = { center: [], user: [] };

    // for now the enviroment
    public enviroment = 'https://store.deloitte.ai';

    public langList: LanguageList = [];
    public selectedLang = 'en';
    public userLoggedIn = false;
    public isInitComplete = false;
    public isUserResolved = false;
    public isOnLoginPage = false;
    public isOnSignUpPage = false;
    public user: any; // IUser;
    public mobileUser = true;

    private subscriptions: Subscription[] = [];

    constructor(
        private loginService: AuthService,
        private stringHandlerService: StringHandlerService,
        private breakpointObserver: BreakpointObserver,
        private cookieService: CookieService,
        private translate: TranslateService,
        private cdRef: ChangeDetectorRef,
        loaderService: LoaderService,
        private router: Router
    ) {
        this.subscriptions.push(
            loaderService.isInitComplete$.subscribe(isInitComplete => {
                this.isInitComplete = isInitComplete;
                cdRef.markForCheck();
            }),
        );
    }

    public ngOnInit() {
        this.langList = this.stringHandlerService.getLangList();

        this.router.events.subscribe(event => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.isOnLoginPage = event.url.match(/\/login/) !== null;
            this.isOnSignUpPage = event.url === '/register';
        });

        this.subscriptions.push(
            this.breakpointObserver.observe([
                Breakpoints.XSmall,
                Breakpoints.Small
            ]).subscribe(result => {
                if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
                    this.mobileUser = true;
                } else {
                    this.mobileUser = false;
                }

                this.cdRef.markForCheck();
            }),

            this.loginService.getUser().subscribe(user => {
                this.isUserResolved = true;

                if (!!user /* && user.ra && user.ra !== '' */) {
                    this.userLoggedIn = true;
                    this.user = user;
                } else {
                    this.userLoggedIn = false;
                }

                this.user = user;

                const lang = this.cookieService.get('lang');
                if (lang && lang !== '') {
                    this.selectedLang = lang;
                }

                this.cdRef.markForCheck();
            })
        );
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public changeLang(selectedLang) {
        this.selectedLang = selectedLang.langCode;
        this.translate.use(selectedLang && selectedLang.langCode ? selectedLang.langCode : this.selectedLang);
        this.cookieService.set('lang', this.selectedLang);
    }

    logout(event: MouseEvent) {

        event.preventDefault();
        event.stopPropagation();

        this.userLoggedIn = false;
        this.loginService.logout().subscribe(
            () => this.router.navigate(['login'])
        );
    }

    openUserMenu() {
        // this.langMenu.close;
        // this.hamburgerMenu.close;
    }
    openLangMenu(e) {
        // console.log(e);
        // this.hamburgerMenu.close;
    }
    openHamburgerMenu() {
        // this.langMenu.close;
        // this.usermenu.close;
    }
}
