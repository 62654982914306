import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { CookieService } from 'ngx-cookie-service';

import { AlertComponent } from './components/alert/alert.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { InitSpinnerComponent } from './components/init-spinner/init-spinner.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { SpinnerButtonComponent } from './components/spinner-button/spinner-button.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';

import { AlertService } from './services/alert.service';
import { LoaderService } from './services/loader.service';
import { StringHandlerService } from './services/string-handler.service';

import { LoaderInterceptorService } from './interceptors/loader.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { AiHubTranslateLoader } from './helpers/ai-hub-translate.loader';

import { CopyClipboardDirective } from './directives/copy-clipboard/copy-clipboard.directive';

export function HttpLoaderFactory(http: HttpClient, loaderService: LoaderService) {
  return new AiHubTranslateLoader(http, loaderService);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, LoaderService]
      }
    }),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
  ],
  providers: [
    CookieService,
    AlertService,
    LoaderService,
    StringHandlerService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}}
  ],
  declarations: [
    AlertComponent,
    FooterComponent,
    HeaderComponent,
    InitSpinnerComponent,
    ImprintComponent,
    LoaderComponent,
    MainComponent,
    NotFoundComponent,
    PrivacyComponent,
    SpinnerButtonComponent,
    TermsOfUseComponent,
    CopyClipboardDirective
  ],
  exports: [
    AlertComponent,
    FooterComponent,
    HeaderComponent,
    InitSpinnerComponent,
    LoaderComponent,
    MainComponent,
    SpinnerButtonComponent,
    TermsOfUseComponent,
    PrivacyComponent,
    CopyClipboardDirective
  ],
  entryComponents: [
    NotFoundComponent
  ]
})
export class SharedModule { }
