<header *ngIf="!mobileUser" style="width:100%;">
  <nav class="navbar navbar-expand-sm fixed-top bg-dark d-inline-flex">
    <!-- left -->
    <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
      <ul class="navbar-nav mr-auto" menu-eft>
        <a [routerLink]="['/']" class="d-inline-flex">
          <img width="170px"
            src="../../../../assets/img/LogoTogether.svg"
            alt="Deloitte Logo"
            class="img-fluid header-logo my-auto mr-1 ml-4 mr-3 d-none d-md-block"
          />
         
        </a>
      </ul>
    </div>

    <!-- center -->
    <div class="navbar-collapse collapse w-100 order-2 dual-collapse2 d-block">
      <ng-content *ngIf="userLoggedIn"></ng-content>
      <ul class="navbar-nav w-100 justify-content-center" *ngIf="userLoggedIn">
        <li *ngFor="let item of menuItems.center">
          <a *ngIf="!item.isExternal" routerLink="{{item.route}}" class="static-nav-item font-weight-bold text-white">
            {{item.label}}
          </a>

          <a *ngIf="item.isExternal" href="{{item.route}}" class="static-nav-item font-weight-bold text-white">
            {{item.label}}
          </a>
        </li>
      </ul>
    </div>

    <!-- right -->
    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2" *ngIf="isUserResolved">
      <ng-content *ngIf="!mobileUser"></ng-content>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" *ngIf="!userLoggedIn && signUpEnabled && !isOnSignUpPage">
          <a routerLink="/register" class="static-nav-item font-weight-bold text-white && !mobileUser">Sign up</a>
        </li>

        <li class="nav-item d-inline-flex h-100" *ngIf="userLoggedIn">
          <button
            mat-button
            [matMenuTriggerFor]="userMenu"
            (menuOpened)="openUserMenu()"
          >
            <img src="../../../../assets/img/My AI (1).svg" />
          </button>
        </li>
        <!--
        <li class="nav-item h-100 d-inline-flex" *ngIf="userLoggedIn">
          <button mat-button [matMenuTriggerFor]="langMenu" (menuOpened)="openLangMenu($event)">
            <span
              [className]="'ml-2 flag-icon flag-icon-' + selectedLang"
            ></span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </li>
        -->
      </ul>
    </div>
  </nav>

  <!-- usermenu -->
  <mat-menu #userMenu="matMenu" overlapTrigger="false">
    <ul class="list-unstyled mb-0 bg-grey">
      <div mat-menu-item class="cursor-none text-white">
        <mat-icon style="color:white" class="m-0">perm_identity</mat-icon><span *ngIf="userLoggedIn"
          style="font-weight: bold;"> {{ user.displayName }}</span>
      </div>

      <li mat-menu-item class="cursor-pointer text-white" *ngFor="let item of menuItems.user">
        <a routerLink="{{item.route}}" class="static-nav-item font-weight-bold text-white">{{item.label}}</a>
      </li>

      <li mat-menu-item class="cursor-pointer w-100 text-white" (click)="logout($event)">
        <a href="#" class="static-nav-item font-weight-bold text-white">
          Logout
        </a>
      </li>
    </ul>
  </mat-menu>
  <!--  <mat-menu #langMenu="matMenu" overlapTrigger="false">
    <ul class="list-unstyled mb-0">
      <li
        mat-menu-item
        class="cursor-pointer"
        *ngFor="let lang of langList; index as i"
        (click)="changeLang(lang)"
      >
        {{ lang.description }}
        <span [className]="'ml-2 flag-icon flag-icon-' + lang.code"></span>
      </li>
    </ul>
  </mat-menu>
  -->

</header>
<header *ngIf="mobileUser">
  <nav class="navbar navbar-expand fixed-top bg-dark row">
    <div
      class="col-2"
      [matMenuTriggerFor]="hamburgerMenu"
      (menuOpened)="openHamburgerMenu()"
    >
      <img src="../../../../assets/img/Menu.svg" class="float-right" />
    </div>
    <div class="col-7 text-center">
        <img width="170px"
        src="../../../../assets/img/LogoTogether.svg"
        alt="Deloitte Logo"
        class="img-fluid header-logo my-auto mr-3 "
      />
    </div>
    <div
      class="col-2"
      [matMenuTriggerFor]="userMenu"
      (menuOpened)="openUserMenu()"
    >
      <img src="../../../../assets/img/User.svg" />
    </div>
  </nav>

  <!-- user menu mobile -->
  <mat-menu #userMenu="matMenu" (menuOpened)="openUserMenu()">
    <ul class="list-unstyled mb-0 bg-grey pt-3">
      <div mat-menu-item class="cursor-none text-white">
        <mat-icon style="color:white" class="m-0">perm_identity</mat-icon><span *ngIf="userLoggedIn"
          style="font-weight: bold;"> {{ user.displayName }}</span>
      </div>

      <li mat-menu-item class="cursor-pointer text-white" *ngFor="let item of menuItems.user">
        <a routerLink="{{item.route}}" class="static-nav-item font-weight-bold text-white">{{item.label}}</a>
      </li>

      <li mat-menu-item class="cursor-pointer w-100 text-white" (click)="logout($event)">
        <a href="#" class="static-nav-item font-weight-bold text-white"> Logout</a>
      </li>
    </ul>
  </mat-menu>

  <mat-menu #hamburgerMenu="matMenu">
    <ul class="list-unstyled mb-0 bg-grey pt-3">
      <li mat-menu-item class="cursor-pointer text-white">
        <a href="deloitte.ai/cognitive-services-platform#landing-header-container"
          class="static-nav-item font-weight-bold text-white">Home</a>
      </li>
      <li mat-menu-item class="cursor-pointer text-white">
        <a href="
          https://deloitte.ai/onboarding
          " class="static-nav-item font-weight-bold text-white">Onboarding</a>
      </li>
      <li mat-menu-item class="cursor-pointer border-top text-white">
        <a [href]="enviroment" class="static-nav-item text-white"
          >Storefront</a
        >
      </li>
      <li mat-menu-item class="cursor-pointer w-100 text-white">
        <a href=" https://deloitte.ai/cognitive-services-platform/faq"
          class="static-nav-item font-weight-bold text-white">F.A.Q.</a>
      </li>
    </ul>
  </mat-menu>
</header>