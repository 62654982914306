import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, zip } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { merge } from 'lodash';

import { LoaderService } from '../services/loader.service';

export class AiHubTranslateLoader implements TranslateLoader {

  constructor(private http: HttpClient, private loaderService: LoaderService) {}

  getTranslation(lang: string): Observable<any> {

    /**
     * wait for both http requests to complete and merge the result of both
     * results together into 1 json file which will returned
     */
    return zip(
      this.http.get(`/assets/i18n/${lang}.json`),
      this.http.get(`/assets/i18n/${lang}-shared.json`)
    )
    .pipe(
      map(([projectTranslation, sharedTranslations]) => merge(sharedTranslations, projectTranslation)),
      tap(() => this.loaderService.completeInit()),
      take(1),
    );
  }
}
