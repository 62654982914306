import { Component, Inject, OnInit, ChangeDetectorRef, Optional } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../auth';
import { AuthRedirectUri, AutoLoginToken, AfterLoginHook } from '../data';

@Component({
  selector: 'aihub-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public isUserLoggedIn = false;

    public isLoading = false;

    constructor(
        @Inject(AuthRedirectUri) private authRedirectUri: AfterLoginHook,
        @Optional() @Inject(AutoLoginToken) private autoLogin: boolean,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cdRef: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.isLoading = true;
        this.authService.isAuthorized().subscribe(authorized => {

            if (authorized) {
                this.isUserLoggedIn = true;
                this.redirect();
                return;
            }

            if (this.autoLogin) {
                this.doLogin();
                return;
            }

            this.isLoading = false;
            this.cdRef.markForCheck();
        });
    }

    public doLogin() {
        this.isLoading = true;
        this.cdRef.markForCheck();
        this.authService.login();
    }

    private redirect() {

        const routeData = this.authRedirectUri(this.activatedRoute);
        this.router.navigate([routeData.path], {
            queryParams: routeData.queryParams
        });
    }
}
