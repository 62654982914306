import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AiHubAuthModule } from './libs/auth';
import { LoginPageModule } from './libs/login-page';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from './libs/shared/shared.module';
import { DownloadMainComponent } from './main';
import { afterLoginHook } from './utils/after-login-hook';

@NgModule({
    declarations: [
        AppComponent,
        DownloadMainComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        AiHubAuthModule.forRoot({
            authProvider: environment.authProvider,
            firebase: environment.firebase,
            jwtInterceptHook: (uri: string) => uri.startsWith(environment.BASE_DOWNLOAD_SERVICE_URI)
        }),
        LoginPageModule.forRoot(afterLoginHook, false)
    ],
    entryComponents: [DownloadMainComponent],
    bootstrap: [ AppComponent ]
})
export class AppModule {
}
