import { Component, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';

import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'aihub-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent implements OnDestroy {

  public isInitialized = false;

  protected subscriptions: Subscription[] = [];

  constructor(
    loaderService: LoaderService,
    cookie: CookieService,
    translate: TranslateService,
    cdRef: ChangeDetectorRef
  ) {
      const lang = cookie.get('lang');
      if (lang && lang !== '') {
          translate.setDefaultLang(lang);
      } else {
          translate.setDefaultLang('en');
      }

      this.subscriptions.push(
        loaderService.isInitComplete$.subscribe(isInitialized => {
          this.isInitialized = isInitialized;
          cdRef.markForCheck();
        })
      );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
