<aihub-ui--loader *ngIf="isLoading"></aihub-ui--loader>

<div class="container align-items-center p-0">
    <div class="col-md-8 col-12 mx-auto">
        <div class="card p-4">
            <div class="card-body">
                <h5 class="card-title">{{ 'login.card-title' | translate }}</h5>
                <h1 class="card-subtitle my-2">{{ 'login.card-header' | translate }}</h1>
                <p class="card-text my-3">{{ 'login.card-description' | translate }}</p>
                <button type="button" class="btn btn-primary font-weight-bold mt-3 d-none d-md-block"
                    (click)="doLogin()"
                    [disabled]="isLoading">
                    {{ 'login.card-btn-text' | translate }}
                    <!--
                    <mat-icon>arrow_forward</mat-icon>
                    -->
                </button>
                <button type="button" class="btn btn-primary font-weight-bold mt-3 d-block d-md-none"
                    (click)="doLogin()"
                    [disabled]="isLoading">
                    {{ 'login.card-btn-text-mobile' | translate }}
                    <!--
                    <mat-icon>arrow_forward</mat-icon>
                    -->
                </button>
            </div>
        </div>
    </div>
</div>
