import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'aihub-spinner-button',
  templateUrl: './spinner-button.component.html'
})
export class SpinnerButtonComponent {
  @Input() public text = '';
  @Input() public icon = 'check';
  @Input() public color = 'primary';
  @Input() public disabled = false;
  @Input() public inProgress = false;
  @Output() public clicked = new EventEmitter();

  public getButtonClasses = () => `btn-${this.color}`;

  public onClicked = () => this.clicked.emit();
}
