import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { DOWNLOAD_SERVICE_BASE_URL } from './app/data';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([{
    provide: DOWNLOAD_SERVICE_BASE_URL,
    useValue: environment.BASE_DOWNLOAD_SERVICE_URI
}])
.bootstrapModule(AppModule)
  .catch(err => console.error(err));
