import { NgModule, ModuleWithProviders, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

import { LoginPageRoutingModule } from './login-routing.module';
import { LoginComponent } from './ui/login.component';
import { AiHubAuthModule, AuthService } from '../../auth';
import { AiHubUIModule } from '../../ui';

import i18_en from './i18n/en.json';
import { AuthRedirectUri, AfterLoginHook } from './data';
import { tap } from 'rxjs/operators';

@NgModule({
    declarations: [
        LoginComponent
    ],
    entryComponents: [
        LoginComponent
    ],
    imports: [
        AiHubAuthModule,
        AiHubUIModule,
        CommonModule,
        LoginPageRoutingModule,
        MatIconModule,
        TranslateModule
    ],
    exports: [
        LoginPageRoutingModule
    ]
})
export class LoginPageModule {

    public static forRoot(
        afterLoginRoute: AfterLoginHook | string,
        autoLogin = false
    ): ModuleWithProviders<LoginPageModule> {
        return {
            ngModule: LoginPageModule,
            providers: [
                {
                    provide: AuthRedirectUri,
                    useFactory: () => typeof afterLoginRoute === 'string' ? () => ({path: afterLoginRoute}) : afterLoginRoute
                },
                { provide: 'AutoLoginEnabled', useValue: autoLogin }
            ]
        };
    }

    public constructor(
        private translateService: TranslateService,
        private authService: AuthService,
        @Inject('AutoLoginEnabled') autoLogin: boolean
    ) {
        this.translateService.setTranslation('en', i18_en, true);

        /**
         * write initial value for auto login in session storage
         */
        sessionStorage.setItem('autoLogin', autoLogin ? 'true' : 'false');

        /**
         * register logout hook which sets autologin to false
         * if the user logged out
         */
        this.authService.addLogoutHook(
            of(true).pipe(tap(() => sessionStorage.setItem('autoLogin', 'false')))
        );
    }
}
