import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'aihub-ui--loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  public show = false;

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
