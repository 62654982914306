import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOWNLOAD_SERVICE_BASE_URL } from '../data';
import { map } from 'rxjs/internal/operators/map';

interface DownloadUri {
  url: string;
}

@Injectable({providedIn: 'root'})
export class DownloadHelper {

    public constructor(
        private http: HttpClient,
        @Inject(DOWNLOAD_SERVICE_BASE_URL) private baseUrl: string
    ) {}

    public fetchData(id: string): Observable<Blob> {
        return this.http.get(this.baseUrl, {
            responseType: 'blob',
            reportProgress: true,
            observe: 'body',
            params: {
                subscription_id: id
            }
        });
    }

    public fetchDownloadUrl(params: any): Observable<string> {
        return this.http.get<DownloadUri>(this.baseUrl, {
            params
        }).pipe(map((data) => data.url));
    }
}
