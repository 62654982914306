import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertService } from '../services/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor( private alertService: AlertService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.router.navigate(['/login']);
                this.alertService.error('Please login again.');
            }

            const error = this.getErrorMessage(err);

            this.alertService.error(error);
            return throwError(error);
        }));
    }

    private getErrorMessage = (err): string => {
      if (err.statusText !== undefined) {
        return err.statusText;
      }

      if (err.error !== undefined) {
        if (err.error.message !== undefined) {
          return err.error.message;
        }

        return JSON.stringify(err.error);
      }

      if (err.data !== undefined) {
        if (err.data.message !== undefined) {
          return err.data.message;
        }
        return JSON.stringify(err.data);
      }

      return JSON.stringify(err);
    }
}
